import React from 'react';
import { Audio } from 'remotion';
import { useAudioData } from '@remotion/media-utils';

import {
  AudioViz as AudioComp, Image, PaginatedSubtitles, Video,
} from './comps';

interface InputProps {
  src?: string;
  srt_file?: string;
  authorName?: string;
  bg_image?: string;
  bg_video?: string;
  music?: any;
  styles?: any;
  width?: any;
  height?: any;
}

function TagsAudioViz({
  src, srt_file, authorName, music, styles, bg_image, bg_video, width, height,
}: InputProps) {
  const audioData = useAudioData(src || '');

  const currentStyles = {
    background_color: styles ? styles.background_color : '#FF8282',
    subtitle_color: styles ? styles.subtitle_color : '#000000',
    active_subtitle_color: styles ? styles.active_subtitle_color : '#00000030',
    wave_color: styles ? styles.wave_color : '#000',
    author_color: styles ? styles.author_color : '#000',
  };

  if (!audioData) return null;

  const isPortraitMode = width != height;

  return (
    <div
      style={bg_image || bg_video ? {} : { backgroundColor: currentStyles.background_color }}
      className="relative w-full h-full"
    >
      {bg_image && (
        <div className="absolute w-full h-full opacity-60 z-10">
          <Image src={bg_image} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
        </div>
      )}
      {bg_video
        && (
          <div className="absolute w-full h-full opacity-60 z-10">
            <Video loop muted src={bg_video} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
          </div>
        )}
      <div className={`w-full relative z-30 h-full flex flex-col justify-between ${isPortraitMode ? 'py-20' : 'py-4'}`}>
        <div className="flex gap-4 px-2">
          {music ? (
            <Audio loop src={music.src} volume={music.volume} />
          ) : null}
          <div className="flex-auto pb-5">
            <PaginatedSubtitles
              subtitlesFileName={srt_file || ''}
              linesPerPage={5}
              subtitlesTextColor={currentStyles.subtitle_color}
              subtitlesBackgroundColor={currentStyles.background_color}
              subtitlesActiveTextColor={currentStyles.active_subtitle_color}
              subtitlesZoomMeasurerSize={1}
              subtitlesLineHeight={100}
              audioOffsetInSeconds={0}
              style={{
                fontFamily: 'Geist Regular',
                fontWeight: 500,
                fontSize: '20px',
                lineHeight: '24px',
                textAlign: 'center',
              }}
            />
          </div>
        </div>
        <AudioComp
          audioSrc={src!}
          waveColor={currentStyles.wave_color}
          style={{ flex: 1 }}
          innerStyle={{ flex: 1 }}
          freqRangeStartIndex={4}
          waveLinesToDisplay={5}
          numberOfSamples={256}
          mirrorWave
        />
        <div className="flex flex-col justify-center items-center text-center">
          <div
            className="p-2 w-fit rounded-full"
            style={{
              backgroundColor: currentStyles.background_color,
              color: currentStyles.author_color,
              fontFamily: 'Geist Regular',
              fontSize: '18px',
              lineHeight: '22px',
              fontWeight: '600',
            }}
          >
            {authorName || 'Lica'}
          </div>
        </div>
      </div>
    </div>

  );
}

export default TagsAudioViz;
