import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { prefetch } from 'remotion';
import { Button, Spacer, Spinner } from '@nextui-org/react';
import { Icon } from '@iconify/react';
import JsonView from 'react18-json-view';
import 'react18-json-view/src/style.css';

import RemotionPlayer from '../../../../remotion/RemotionPlayer';
import useFetch from '../../../../hooks/useFetch';

function EditVideoConfig() {
  const urlParams = useParams();

  const [
    contentRequest,
    setContentRequest,
  ] = useState<{ loading: boolean, loaded: boolean, loadingFailed: boolean, data: any }>(
    {
      loading: true, loaded: false, loadingFailed: false, data: null,
    },
  );
  const [isMediaCached, setIsMediaCached] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [editorData, setEditorData] = useState<any>(null);
  const [updatedVideoData, setUpdatedVideoData] = useState(null);

  const { fetchData: getVideo } = useFetch<any>(
    `${import.meta.env.VITE_BACKEND_URL}/api/v1/contents/${urlParams.contentId}/`,
    { method: 'GET' },
  );

  const { fetchData: saveData } = useFetch<any>(
    `${import.meta.env.VITE_BACKEND_URL}/api/v1/contents/${urlParams.contentId}/update`,
    { method: 'POST' },
  );

  const { fetchData: downloadData } = useFetch<any>(
    `${import.meta.env.VITE_BACKEND_URL}/api/v1/download/`,
    { method: 'POST' },
  );

  const fetchVideo = async () => {
    try {
      const responseData = await getVideo();

      if (responseData?.data?.status === 'completed') {
        setContentRequest((prevState) => ({
          ...prevState,
          loading: false,
          loaded: true,
          data: {
            ...responseData.data.output,
          },
        }));
        setEditorData({ ...responseData.data.output.video_data });
      } else if (responseData?.data?.status === 'failed') {
        setContentRequest((prevState) => ({
          ...prevState,
          loading: false,
          loaded: false,
          loadingFailed: true,
          data: null,
        }));
      } else {
        setTimeout(() => {
          fetchVideo();
        }, 10000);
      }
    } catch (err) {
      console.log(err);
    }
  };

  function getMediaLinks(data: any) {
    let links: string[] = [];
    for (const key in data) {
      const val = data[key];

      if (val === null) continue;

      if (typeof val === 'object') {
        links = links.concat(getMediaLinks(val));
      } else if (Array.isArray(val)) {
        val.forEach((obj: any) => {
          links = links.concat(getMediaLinks(obj));
        });
      } else if (typeof val === 'string') {
        try {
          const url = new URL(val);
          const isMedia = /\.(mp4|webm|jpg|jpeg|svg|png|webp|avif|gif|mp3)$/.test(url.pathname);
          if (isMedia) {
            links.push(val);
          }
        } catch (e) { /* empty */
        }
      }
    }
    return links;
  }

  useEffect(() => {
    if (contentRequest.loading && !contentRequest.data) {
      fetchVideo().then(() => {
      });
    } else if (contentRequest.data) {
      const promises: any[] = [];
      const mediaLinks = getMediaLinks(contentRequest.data);
      mediaLinks.forEach((link) => {
        promises.push(prefetch(link).waitUntilDone());
      });
      Promise.allSettled(promises).then(() => {
        setIsMediaCached(true);
      });
    }
  }, [contentRequest]);

  const isVideoReady = () => contentRequest.loaded && isMediaCached;

  const handleDownloadClick = async () => {
    if (isDownloading) {
      return;
    }

    setIsDownloading(true);

    const data = {
      composition: 'Template',
      inputProps: updatedVideoData || contentRequest.data.video_data,
    };

    try {
      const response = await downloadData({
        body: JSON.stringify(data),
      });

      if (response) {
        const result = response;
        const downloadLink = document.createElement('a');
        downloadLink.href = result.outputFile;

        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      }

      setIsDownloading(false);
    } catch (err) {
      console.log(err);
    }
  };

  const handlePreviewClick = async () => {
    setUpdatedVideoData({
      ...editorData,
    });
  };

  const handleSaveClick = async () => {
    if (isSaving) {
      return;
    }

    setIsSaving(true);

    const data = {
      output: {
        video_data: updatedVideoData || contentRequest.data.video_data,
      },
    };

    try {
      await saveData({
        body: JSON.stringify(data),
      });

      setIsSaving(false);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="p-4 h-screen">
      {isVideoReady() ? (
        <div className="flex flex-col h-full items-center overflow-hidden">
          <div className="flex-shrink-0" style={{ color: '#B6B6B6' }}>
            {contentRequest.data?.title}
          </div>
          <Spacer y={4} />
          <div className="flex flex-row justify-between flex-auto w-full gap-8 overflow-hidden">
            <div className="flex-auto max-w-[750px]">
              <div className="flex flex-col h-full">
                <div className="flex-auto bg-white overflow-scroll">
                  <JsonView
                    src={editorData}
                    editable
                    onEdit={(data) => {
                      setEditorData(data.src);
                    }}
                  />
                </div>
                <Spacer y={4} />
                <div className="flex items-center justify-center flex-shrink-0 gap-4">
                  <Button
                    className="rounded-md text-base font-medium"
                    style={{
                      color: '#939393',
                      backgroundColor: '#2B2B2B',
                    }}
                    onClick={handlePreviewClick}
                  >
                    Preview
                  </Button>
                  <Button
                    className="rounded-md text-base font-medium"
                    style={{
                      color: '#939393',
                      backgroundColor: '#2B2B2B',
                    }}
                    isLoading={isSaving}
                    onClick={handleSaveClick}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </div>
            <div className="flex-auto flex-shrink-0 min-w-[400px]">
              <div className="flex flex-col flex-auto items-center justify-between rounded-lg h-full">
                <div
                  style={{
                    aspectRatio:
                      contentRequest.data.video_data.width
                      / contentRequest.data.video_data.height,
                    background: '#2B2B2B',
                  }}
                  className="rounded-xl flex-auto"
                >
                  <RemotionPlayer
                    data={updatedVideoData || contentRequest.data.video_data}
                    clickToPlay={false}
                  />
                </div>
                <Spacer y={4} />
                <div className="flex items-center justify-center flex-shrink-0">
                  <Button
                    className="rounded-md text-base font-medium"
                    startContent={<Icon icon="solar:download-minimalistic-bold" />}
                    style={{
                      color: '#939393',
                      backgroundColor: '#2B2B2B',
                    }}
                    isLoading={isDownloading}
                    onClick={handleDownloadClick}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (!contentRequest.loading && contentRequest.loadingFailed) ? (
        <div className="flex items-center justify-center h-full">
          <div style={{ color: '#B6B6B6' }}>Couldn't generate the video. Please try again.</div>
        </div>
      ) : (
        <div className="flex items-center justify-center h-full">
          <Spinner color="current" />
        </div>
      )}
    </div>
  );
}

export default EditVideoConfig;
