import { Spacer, Spinner } from '@nextui-org/react';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import gsap from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';

import useFetch from '@/hooks/useFetch';
import LicaPage from '@/components/LicaPage';

import MultiContent from './multi-content';
import SingleContent from './single-content';

gsap.registerPlugin(ScrollToPlugin);

interface PageRequestState {
  loading: boolean;
  loaded: boolean;
  loadingFailed: boolean;
  data: any;
}

function Page() {
  const { pageId } = useParams();
  const multiContentContainer = useRef<HTMLDivElement>(null);

  const { fetchData: getPage } = useFetch<any>(
    `${import.meta.env.VITE_BACKEND_URL}/api/v1/pages/${pageId}/`,
    { method: 'GET' },
  );

  const [pageRequest, setPageRequest] = useState<PageRequestState>({
    loading: true,
    loaded: false,
    loadingFailed: false,
    data: null,
  });
  const [selectedCategory, setSelectedCategory] = useState('VIDEO' || 'PODCAST' || 'BLOG');
  const [isProgrammaticScroll, setProgrammaticScroll] = useState(false);

  const fetchPage = async () => {
    try {
      const responseData = await getPage();

      if (responseData?.data?.status === 'processing' || responseData?.data?.status === 'completed') {
        setPageRequest((prevState) => ({
          ...prevState,
          loading: false,
          loaded: true,
          data: responseData.data.output,
        }));
      } else if (responseData?.data?.status === 'failed') {
        setPageRequest((prevState) => ({
          ...prevState,
          loading: false,
          loaded: false,
          loadingFailed: true,
          data: null,
        }));
      } else {
        setTimeout(() => {
          fetchPage();
        }, 10000);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (pageRequest.loading && !pageRequest.data) {
      fetchPage().then(() => {
      });
    }
  }, [pageRequest]);

  // Setting up inital selected category
  useEffect(() => {
    if (pageRequest.data) {
      setSelectedCategory(pageRequest.data.content_ids[0].content_type);
    }
  }, [pageRequest]);

  useEffect(() => {
    const handleScroll = () => {
      if (!multiContentContainer.current) return;
      const children = Array.from(multiContentContainer.current.children) as HTMLElement[];
      children.forEach((child) => {
        if (isElementInView(child) && !isProgrammaticScroll) {
          if (child.id === 'BLOG-SCROLL') {
            setSelectedCategory('BLOGPOST');
          }
          if (child.id === 'VIDEO-SCROLL') {
            setSelectedCategory('VIDEO');
          }
          if (child.id === 'PODCAST-SCROLL') {
            setSelectedCategory('PODCAST');
          }
        }
      });
    };
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };

    function isElementInView(element: HTMLElement): boolean {
      const rect = element.getBoundingClientRect();
      const viewportHeight = window.innerHeight || document.documentElement.clientHeight;

      const targetThreshold = viewportHeight * 0.1;

      return (
        rect.top >= 0
        && rect.top < viewportHeight
        && Math.abs(rect.top - targetThreshold) <= viewportHeight * 0.1
      );
    }
  }, [isProgrammaticScroll]);

  function ScrollToCategory() {
    return (
      <div className="sticky top-0 z-50 bg-white">
        <div className="flex z-10 top-0.5 relative items-center h-14">
          {pageRequest.data?.content_ids.map(
            (content: { content_type: string; content_id: string; }) => {
              const scrollMenuClickHandler = () => {
                setProgrammaticScroll(true);
                setSelectedCategory(content.content_type);
                gsap.to(window, {
                  scrollTo: {
                    y: `#${content.content_type === 'BLOGPOST' ? 'BLOG' : content.content_type}-SCROLL`,
                    offsetY: 100, // Scrolls 100 pixels above the element
                    autoKill: false,
                  },
                  onComplete: () => {
                    setTimeout(() => setProgrammaticScroll(false), 500);
                  },
                });
              };

              return (
                <div
                  onClick={scrollMenuClickHandler}
                  className="w-36 cursor-pointer justify-end flex flex-col items-center gap-4 h-full"
                  key={content.content_id}
                >
                  <div className="w-full justify-center flex gap-2">
                    <img width={20} src={`/multiselect-${content.content_type.toLowerCase()}.svg`} />
                    <div
                      className="font-semibold first-letter:uppercase"
                    >
                      {content.content_type === 'BLOGPOST' ? 'Blog' : content.content_type.toLowerCase()}
                    </div>
                  </div>
                  <div
                    className={`${selectedCategory === content.content_type && 'bg-[#5046E5]'} h-1 w-full rounded-full`}
                  />
                </div>
              );
            },
          )}
        </div>
        <div className="relative bottom-[0px] h-0.5 mb-8 rounded-full w-full bg-[#E9EDF1]" />
      </div>
    );
  }

  const pages = {
    blog: pageRequest.data?.content_ids[0].content_type === 'BLOGPOST',
    podcast: pageRequest.data?.content_ids[0].content_type === 'PODCAST',
    video: pageRequest.data?.content_ids[0].content_type === 'VIDEO',
  };

  if (pageRequest.loaded) {
    if (pageRequest.data.content_ids.length === 1) {
      return (
        <LicaPage showFooter>
          <div
            className={`pt-4 ${pages.blog ? 'h-full' : pages.podcast ? 'h-or-w-sm:h-full flex items-center flex-col justify-center h-or-w-sm:py-20 min-h-[calc(100vh-128px)]' : pages.video ? "h-fit min-lg:h-[calc(100vh-128px)]" : 'h-[calc(100vh-128px)]'}
`}
          >
            <SingleContent
              key={pageRequest.data.content_ids[0].content_id}
              contentId={pageRequest.data.content_ids[0].content_id}
              contentType={pageRequest.data.content_ids[0].content_type}
            />
          </div>
        </LicaPage>
      );
    }

    return (
      <LicaPage showFooter>
        <div ref={multiContentContainer} className="sm:pt-4 multi-content-container">
          {pageRequest.data.content_ids.length === 1 ? (
            <SingleContent
              key={pageRequest.data.content_ids[0].content_id}
              contentId={pageRequest.data.content_ids[0].content_id}
              contentType={pageRequest.data.content_ids[0].content_type}
            />
          ) : (
            <>
              <ScrollToCategory />
              {pageRequest.data.content_ids.map(
                (content: { content_type: string; content_id: string; }) => (
                  <>
                    <MultiContent
                      key={content.content_id}
                      contentId={content.content_id}
                      contentType={content.content_type}
                    />
                    <Spacer y={16} />
                  </>
                ),
              )}
            </>
          )}
        </div>
      </LicaPage>
    );
  }
  if (!pageRequest.loading && pageRequest.loadingFailed) {
    return (
      <LicaPage>
        <div className="flex justify-center items-center h-full bg-white" style={{ minHeight: 'inherit' }}>
          <div style={{ color: '#B6B6B6' }}>
            Couldn't generate the content. Please try again.
          </div>
        </div>
      </LicaPage>
    );
  }
  return (
    <LicaPage>
      <div
        className="flex justify-center flex-col gap-5 dark:text-secondary-dark text-secondary-light items-center h-full"
        style={{ minHeight: 'inherit' }}
      >
        <Spinner color='current' size="lg" />
        Loading Content...
      </div>
    </LicaPage>
  );
}

export default Page;
