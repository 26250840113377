import React, {
	useState,
	useRef,
	ForwardedRef,
	forwardRef,
} from "react";

import gsap from "gsap";
import { linearTiming, TransitionSeries } from "@remotion/transitions";
import { slide } from "@remotion/transitions/slide";
import { fade } from "@remotion/transitions/fade";
import classNames from "classnames";
import { ScrollTrigger } from "gsap/all";

import Animated from "../animated";
import CarouselSlide from "./carousel-slide";
import { useGsapTimeline } from "../../animation/gsap";

interface Props {
	component: any;
}

const Carousel = forwardRef((props: Props, ref: ForwardedRef<any>) => {
	const [currentIndex, setCurrentIndex] = useState(0);
	const dotsRefs = useRef<any>([]);

	useGsapTimeline<HTMLDivElement>(() => {
		gsap.registerPlugin(ScrollTrigger);
		const gsapTimeline = gsap.timeline();

		dotsRefs.current.forEach((dotRef: any) => {
			gsapTimeline.from(dotRef, {
				duration: 0.2,
				y: 50,
				opacity: 0,
				ease: "power3.out",
			});
		});
		return gsapTimeline;
	});

	return (
		<div ref={ref} className="mx-[30px]">
			<TransitionSeries>
				{props?.component?.items?.map((item: any, index: any) => (
					<>
						<TransitionSeries.Sequence durationInFrames={100} key={index}>
							<CarouselSlide
                				title={item.title}
								imageSrc={item.image}
								imageStyle={props?.component?.imageStyle}
								count={props?.component?.items.length}
								index={index}
								setIndex={() => setCurrentIndex(index)}
							/>
						</TransitionSeries.Sequence>
						<TransitionSeries.Transition
							presentation={slide({direction: "from-right"})}
							timing={linearTiming({durationInFrames: 10})}
						/>
					</>
				))}
			</TransitionSeries>
			<TransitionSeries>
				{props?.component?.items?.map((item: any, index: any) => (
					<>
						<TransitionSeries.Sequence durationInFrames={100} key={index}>
							<div className="mt-10">
								<Animated
									component={{
										style: props?.component?.titleStyle,
										text: item.title,
										type: "TEXT",
										animation: {type: "rise"},
									}}
								/>
							</div>
						</TransitionSeries.Sequence>
						<TransitionSeries.Transition
							presentation={fade()}
							timing={linearTiming({durationInFrames: 10})}
						/>
					</>
				))}
			</TransitionSeries>
			<div
				className="flex w-full justify-start absolute"
				style={props?.component?.indicatorStyle}
			>
				{[...Array(props?.component?.items.length)].map((_, index) => (
					<button
						key={index}
						ref={(el) => (dotsRefs.current[index] = el)}
						className={classNames(
							"w-5 h-5 rounded-full mx-2 focus:outline-none",
							currentIndex === index ? "bg-gray-900" : "bg-gray-400",
						)}
					></button>
				))}
			</div>
		</div>
	);
});

Carousel.displayName = "Carousel";


export default Carousel;
