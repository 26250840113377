import styled from "styled-components";

import Text from "./text";

const Container = styled.div`
		border-left: 1px solid #FED702;
    background: rgba(19, 3, 10, 0.70);
		padding: 16px;
`;

export default (props: any) => {
	return (
		<Container style={{...props.style}}>
			<Text text={props.text} style={{color: "white", fontSize: "18px", lineHeight: "22px"}} />
		</Container>
	);
};
