import React, { useEffect, useState } from 'react';

interface LoadingScreenProps {
    contentLoaded: boolean;
    totalSteps: number | string[];
}

const defaultSteps = [
    'Screenplay',
    'Setting Up Layout',
    'Adding Text',
    'Retrieving Visuals',
    'Generating Voice Over',
    'Adding Final Touches',
];

export default function LoadingScreen({ contentLoaded, totalSteps }: LoadingScreenProps) {
    const [currentStep, setCurrentStep] = useState(1);

    const steps = typeof totalSteps === 'number' ? defaultSteps.slice(0, totalSteps) : totalSteps;

    useEffect(() => {
        let intervalId: NodeJS.Timeout;
        if (contentLoaded && currentStep !== steps.length + 1) {
            intervalId = setInterval(() => {
                setCurrentStep((prevStep) => {
                    if (prevStep < steps.length + 1) {
                        return prevStep + 1;
                    } else {
                        clearInterval(intervalId);
                        return prevStep;
                    }
                });
            }, 500);
        } else if (!contentLoaded && currentStep < steps.length) {
            intervalId = setInterval(() => {
                setCurrentStep((prevStep) => {
                    if (prevStep < steps.length + 1) {
                        return prevStep + 1;
                    } else {
                        clearInterval(intervalId);
                        return prevStep;
                    }
                });
            }, 10000);
        }

        return () => clearInterval(intervalId);
    }, [currentStep, totalSteps, contentLoaded]);

    const Tick = () => (
        <svg
            className="!text-tertiary-light h-10 dark:!text-secondary-dark relative right-0.5"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.99984 17.3333C13.6022 17.3333 17.3332 13.6024 17.3332 9C17.3332 4.39763 13.6022 0.666668 8.99984 0.666668C4.39746 0.666668 0.666504 4.39763 0.666504 9C0.666504 13.6024 4.39746 17.3333 8.99984 17.3333ZM12.8265 6.88374C13.0384 6.61128 12.9894 6.2186 12.7169 6.00669C12.4444 5.79477 12.0518 5.84385 11.8398 6.11632L8.50066 10.4095C8.42641 10.505 8.28674 10.5174 8.19685 10.4365L6.08461 8.53547C5.82804 8.30456 5.43286 8.32536 5.20195 8.58193C4.97104 8.8385 4.99184 9.23368 5.24841 9.46459L7.36064 11.3656C7.98992 11.9319 8.96759 11.8452 9.48735 11.177L12.8265 6.88374Z"
                fill="currentColor"
            />
        </svg>
    );

    return (
        <div className="flex flex-col">
            {steps.map((step, index) => (
                <div key={index} className="flex flex-col w-full">
                    <div className={`flex text-xl items-center w-full gap-5`}>
                        {index + 1 < currentStep ? <Tick /> : <div className='w-9 h-9 bg-[#FB7185] dark:bg-[#323232] dark:border dark:border-secondary-dark rounded-full' />}
                        <div className="flex flex-col w-60">
                            <div className={(index + 1 === currentStep || (index + 1 === steps.length && currentStep > steps.length)) ? 'font-bold scale-110 duration-300 relative left-3' : 'duration-300'}>{step}</div>
                        </div>
                    </div>
                    <div className='w-9 h-28 flex justify-center'>
                        {index !== steps.length - 1 && (
                            <div className="border-l-2 border-dotted border-tertiary-light dark:border-primary-light h-full"></div>
                        )}
                    </div>
                </div>
            ))}
        </div>
    );
}