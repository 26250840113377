import { Divider, Spacer, Spinner } from '@nextui-org/react';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { prefetch } from 'remotion';

import Video from './video';
import Podcast from './podcast';
import Blog from './blog';
import { CONTENT_TYPE_TO_TITLE, getMediaLinks } from '../../../../utils/common';
import ProgressList from '../../../../components/ProgressList';
import useFetch from '../../../../hooks/useFetch';
import PodcastNew from './podcast-new';

interface ContentRequestState {
  loading: boolean;
  loaded: boolean;
  loadingFailed: boolean;
  data: any;
}

function MultiContent({ contentId, contentType = 'VIDEO' }: { contentId: string, contentType: string }) {
  const [searchParams] = useSearchParams();

  const [contentRequest, setContentRequest] = useState<ContentRequestState>({
    loading: true,
    loaded: false,
    loadingFailed: false,
    data: null,
  });
  const [isMediaCached, setIsMediaCached] = useState(false);
  const [progressListCompleted, setProgressListCompleted] = useState(false);

  const { fetchData: getContent } = useFetch<any>(
    `${import.meta.env.VITE_BACKEND_URL}/api/v1/contents/${contentId}/`,
    { method: 'GET' },
  );

  const fetchContent = async () => {
    try {
      const responseData = await getContent();

      if (responseData?.data?.status === 'completed') {
        setContentRequest((prevState) => ({
          ...prevState,
          loading: false,
          loaded: true,
          data: responseData.data,
        }));
      } else if (responseData?.data?.status === 'failed') {
        setContentRequest((prevState) => ({
          ...prevState,
          loading: false,
          loaded: false,
          loadingFailed: true,
          data: null,
        }));
      } else {
        setTimeout(() => {
          fetchContent();
        }, 10000);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (contentRequest.loading && !contentRequest.data) {
      fetchContent().then(() => {
      });
    } else if (contentRequest.data) {
      const promises: any[] = [];
      const mediaLinks = getMediaLinks(contentRequest.data);
      mediaLinks.forEach((link) => {
        promises.push(prefetch(link).waitUntilDone());
      });
      Promise.allSettled(promises).then(() => {
        setIsMediaCached(true);
      });
    }
  }, [contentRequest]);

  const shouldRenderOutput = () => {
    if (searchParams.get('progress')) {
      return progressListCompleted && contentRequest.loaded && isMediaCached;
    }
    return contentRequest.loaded && isMediaCached;
  };

  return (
    <div
      id={`${CONTENT_TYPE_TO_TITLE[contentType].toUpperCase()}-SCROLL`}
      className="h-full flex flex-col items-center min-h-[600px] bg-[#F5F7FA] p-6 rounded-lg"
    >
      <div className="w-full text-xl font-semibold">{CONTENT_TYPE_TO_TITLE[contentType]}</div>
      <Spacer y={2} />
      <Divider className="bg-[#E9EDF1] h-[2px]" />
      <Spacer y={8} />
      {shouldRenderOutput() ? (
        contentType === 'VIDEO' ? (
          <Video
            contentId={contentId}
            videoData={contentRequest.data.output.video_data}
            responseData={contentRequest.data}
          />
        ) : contentType === 'PODCAST' ? (
          contentRequest.data.output.version === 'v2' ? (
            <PodcastNew
              contentId={contentId}
              data={contentRequest.data}
            />
          ) : (
            <Podcast
              contentId={contentId}
              data={contentRequest.data}
            />
          )
        ) : contentType === 'BLOGPOST' ? (
          <Blog
            contentId={contentId}
            data={contentRequest.data.output}
            responseData={contentRequest.data}
          />
        ) : null
      ) : !contentRequest.loading && contentRequest.loadingFailed ? (
        <div className="flex-auto flex justify-center items-center">
          <div style={{ color: '#B6B6B6' }}>
            Couldn't generate the content. Please try again.
          </div>
        </div>
      ) : (
        <div className="flex-auto flex justify-center items-center">
          {searchParams.get('progress') ? (
            <ProgressList
              items={contentType === 'VIDEO' ? [
                'Generating design actions for video creation',
                'Creating the screenplay',
                'Setting up the scenes with the right assets',
                'Adding animations',
                'Adding transitions',
                'Mixing music',
                'Exporting everything into a video file',
                'Rendering',
              ] : contentType === 'PODCAST' ? [
                'Generating the screenplay',
                'Generating the dialogues',
                'Generating voices for actors',
                'Orchestrating sounds between dialogues',
                'Stitching them together into an mp3 file',
                'Generating subtitles',
                'Sending it as an mp4',
              ] : [
                'Summarizing the input text',
                'Retrieving image assets',
                'Selecting a style and generating code for it',
                'Putting it together',
              ]}
              onFinish={() => {
                setProgressListCompleted(true);
              }}
            />
          ) : (
            <div className="flex flex-col gap-5 dark:text-secondary-dark text-secondary-light">
              <Spinner color="current" size="lg" />
              Loading Content...
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default MultiContent;
