import { Button, Spacer } from '@nextui-org/react';
import { Icon } from '@iconify/react';
import React, { useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import AuthContext from '@/auth/context';
import RemotionPlayer from '@/remotion/RemotionPlayer';

function Video({ videoData }: { videoData: any }) {
  const authContext = useContext(AuthContext);

  const urlParams = useParams();
  const navigate = useNavigate();

  const [isDownloading, setIsDownloading] = useState(false);

  const handleDownloadClick = async () => {
    if (isDownloading) {
      return;
    }

    const token = await authContext.currentUser?.getIdToken();
    if (!token) return;

    setIsDownloading(true);
    const data = {
      composition: 'Template',
      inputProps: videoData,
    };
    try {
      const response = await fetch(
        `${import.meta.env.VITE_BACKEND_URL}/api/v1/download/`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(data),
        },
      );
      const result = await response.json();

      const downloadLink = document.createElement('a');
      downloadLink.href = result.outputFile;

      const contentDisposition = response.headers.get('Content-Disposition');
      const filenameMatch = contentDisposition && contentDisposition.match(/filename="(.+?)"/);
      downloadLink.download = filenameMatch ? filenameMatch[1] : 'output_video';
      document.body.appendChild(downloadLink);
      downloadLink.click();

      document.body.removeChild(downloadLink);

      setIsDownloading(false);
    } catch (err) {
      console.log(err);
    }
  };

  const handleEditClick = async () => {
    navigate(`/videos/${urlParams.videoId}/edit`);
  };

  return (
    <div className="flex flex-col flex-auto items-center justify-between rounded-lg w-full">
      <div
        style={{
          aspectRatio: videoData.width / videoData.height,
        }}
        className="rounded-xl flex-auto max-w-full max-h-full"
      >
        <RemotionPlayer
          data={videoData}
          clickToPlay={false}
        />
      </div>
      <Spacer y={4} />
      <div className="flex items-center justify-center gap-4">
        <Button
          className="rounded-md text-base font-medium"
          startContent={<Icon icon="solar:download-minimalistic-bold" />}
          style={{
            color: '#939393',
            backgroundColor: '#2B2B2B',
          }}
          isLoading={isDownloading}
          onClick={handleDownloadClick}
        />
        <Button
          className="rounded-md text-base font-medium"
          startContent={<Icon icon="solar:pen-linear" />}
          style={{
            color: '#939393',
            backgroundColor: '#2B2B2B',
          }}
          onClick={handleEditClick}
        />
      </div>
    </div>
  );
}

export default Video;
