import { Audio, useCurrentFrame, useVideoConfig } from 'remotion';
import { useAudioData, visualizeAudio } from '@remotion/media-utils';
import { CSSProperties } from 'styled-components';

interface AudioCompProps {
  waveColor: string;
  numberOfSamples: number;
  freqRangeStartIndex: number;
  waveLinesToDisplay: number;
  mirrorWave: boolean;
  audioSrc: string;
  style?: CSSProperties;
  innerStyle?: CSSProperties;
  outerStyle?: CSSProperties;
  frequencyHeight?: number;
}

function AudioComp({
  waveColor,
  numberOfSamples,
  freqRangeStartIndex,
  waveLinesToDisplay,
  mirrorWave,
  audioSrc,
  style,
  innerStyle,
  outerStyle,
  frequencyHeight = 200,
}: AudioCompProps) {
  const frame = useCurrentFrame();
  const { fps } = useVideoConfig();

  const audioData = useAudioData(audioSrc);

  if (!audioData) {
    return null;
  }
  const waveNumberOfSamples = Number(numberOfSamples);

  const frequencyData = visualizeAudio({
    fps,
    frame,
    audioData,
    numberOfSamples: waveNumberOfSamples,
  });

  // Pick the low values because they look nicer than high values
  // feel free to play around :)
  const frequencyDataSubset = frequencyData.slice(
    freqRangeStartIndex,
    freqRangeStartIndex
    + (mirrorWave ? Math.round(waveLinesToDisplay / 2) : waveLinesToDisplay),
  );

  const frequenciesToDisplay = mirrorWave
    ? [...frequencyDataSubset.slice(1).reverse(), ...frequencyDataSubset]
    : frequencyDataSubset;

  return (
    <div style={{ width: '100%', height: '100%', ...style }}>
      <Audio src={audioSrc} />
      <div className="flex flex-auto items-center justify-around w-full h-full gap-0.5" style={{ ...outerStyle }}>
        {frequenciesToDisplay.map((v, i) => (
          <div
            key={i}
            className="bar"
            style={{
              minWidth: '1px',
              backgroundColor: waveColor,
              height: `${frequencyHeight * Math.sqrt(v)}%`,
              borderRadius: '999px',
              width: '2px',
              ...innerStyle,
            }}
          />
        ))}
      </div>
    </div>
  );
}

export default AudioComp;
