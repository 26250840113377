import { useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Avatar, Dropdown, DropdownTrigger, DropdownMenu, DropdownItem,
} from '@nextui-org/react';
import { Icon } from '@iconify/react';

import AuthContext from '@/auth/context';
import { signOut } from '@/auth/auth';

function Header() {
  const authContext = useContext(AuthContext);
  const location = useLocation();
  const navigate = useNavigate();
  const activePage = location.pathname;

  const handleSignOut = () => {
    signOut().then(() => {
      navigate('/login');
    });
  };

  return (
    <div
      className="w-[123px] sm:w-full h-full sm:h-[56px] p-5 sm:px-4 sm:py-3 border-r border-zinc-800 flex-col sm:flex-row justify-between items-start inline-flex top-0 left-0 bottom-0"
    >
      <div className="flex-col justify-start items-start gap-6 inline-flex sm:flex-row sm:w-full">
        <div className="text-center text-neutral-200 text-lg font-medium font-['Inter'] leading-7">
          Lica
          {import.meta.env.MODE === 'production' ? '' : import.meta.env.MODE}
        </div>
        <div
          className="flex flex-col justify-center items-start gap-3 sm:flex-row sm:w-full sm:justify-between sm:items-center"
        >
          <div className="flex flex-col justify-center items-start sm:items-center gap-2 sm:gap-4 sm:flex-row">
            {authContext.currentUser && (
              <div
                className="py-1 sm:py-0 rounded-lg justify-center items-center gap-2 inline-flex"
                role="button"
                onClick={() => navigate('/')}
              >
                <div className="w-6 h-6 relative">
                  <Icon
                    height={window.innerWidth <= 519 ? 28 : 24}
                    icon={activePage === '/' ? 'solar:videocamera-bold' : 'solar:videocamera-linear'}
                    style={{
                      color: activePage === '/' ? '#DDDDDD' : '#7C7C7C',
                    }}
                  />
                </div>
                <div
                  className={`text-center text-sm font-medium leading-tight ${activePage === '/' ? 'text-neutral-200' : 'text-neutral-500'} sm:hidden`}
                >
                  Create
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {
        authContext.currentUser && (
          <Dropdown
            classNames={{
              content: 'bg-zinc-800 p-0',
              base: 'p-0',
            }}
          >
            <DropdownTrigger>
              <Avatar src={authContext.currentUser?.photoURL || undefined} as="button" className="w-8 h-8" />
            </DropdownTrigger>
            <DropdownMenu
              aria-label="Static Actions"
              variant="flat"
              className="text-neutral-400 p-0"
              itemClasses={{
                base: [
                  'data-[hover=true]:bg-neutral-700',
                  'data-[hover=true]:text-neutral-200',
                  'data-[hover=true]:rounded-none',
                ],
              }}
            >
              <DropdownItem
                key="profile"
                startContent={<Avatar src={authContext.currentUser?.photoURL || undefined} className="w-8 h-8" />}
                showDivider
                className="p-3 data-[hover=true]:rounded-t-lg"
              >
                {authContext.currentUser.displayName}
              </DropdownItem>
              <DropdownItem
                key="logout"
                className="p-3 data-[hover=true]:rounded-b-lg"
                onClick={handleSignOut}
              >
                Log out
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        )
      }
    </div>
  );
}

export default Header;
