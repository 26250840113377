import {
  forwardRef, useEffect, useMemo, useRef, useState,
} from 'react';
import { CSSProperties } from 'styled-components';
import gsap from 'gsap';
import { SplitText } from 'gsap/SplitText';

import Text from './text';
import { useGsapTimeline } from '../animation/gsap';
import { AnimationFactory } from '../animation/animation';
import Image from './image';

gsap.registerPlugin(SplitText);

interface ContentItem {
  items: any;
  itemStyles: any;
  itemAnimations: any;
}

interface ListTextProps {
  data: ContentItem;
  style: CSSProperties;
  slideDuration: number;
}

const useItemAnimations = (textRef: any, backgroundRef: any, imageRef: any, animations: any, duration: number, delay: number) => {
  useGsapTimeline(() => {
    const split = new SplitText(textRef.current, { type: 'words,chars,lines' });
    let animatedElements;
    switch (animations.title.attributes?.divider) {
      case 'lines':
        animatedElements = split.lines;
        break;
      case 'words':
        animatedElements = split.words;
        break;
      case 'chars':
        animatedElements = split.chars;
        break;
      default:
        animatedElements = [textRef.current];
    }
    const animationFactory = AnimationFactory.createAnimation(animations.title.type);
    const { gsapTimeline } = animationFactory.createTimeline(animatedElements, {
      delay: delay + 0.2,
      ...animations.title.attributes,
      duration,
    });
    return gsapTimeline;
  });

  useGsapTimeline(() => {
    const animationFactory = AnimationFactory.createAnimation(animations.background.type);
    const { gsapTimeline } = animationFactory.createTimeline(backgroundRef.current, {
      ...animations.background.attributes,
      duration,
      delay,
    });

    return gsapTimeline;
  });
  useGsapTimeline(() => {
    const animationFactory = AnimationFactory.createAnimation(animations.background.type);
    const { gsapTimeline } = animationFactory.createTimeline(imageRef.current, {
      ...animations.background.attributes,
      duration,
      delay,
    });

    return gsapTimeline;
  });
};

function AnimatedItem({
  item, delay, style, animations, duration, length,
}: any) {
  const textRef = useRef<HTMLDivElement>(null);
  const textContainer = useRef<HTMLDivElement>(null);
  const backgroundRef = useRef<HTMLDivElement>(null);
  const imageRef = useRef<HTMLDivElement>(null);
  const [fontSize, setFontSize] = useState(parseFloat(style.titleStyle.fontSize) || 16); // Default font size

  useItemAnimations(textRef, backgroundRef, imageRef, animations, duration, delay);

  useEffect(() => {
    const textHeight = textRef.current?.clientHeight || 0;
    const containerHeight = textContainer.current?.clientHeight || 0;
    if (textHeight > containerHeight) {
      setFontSize((prev: any) => prev - 1);
    }
  });

  useEffect(() => {
    if (!textRef.current) return;
    textRef.current.style.fontSize = `${fontSize}px`;
  }, [fontSize]);

  return (
    <div ref={textContainer} className="relative" style={style.parentStyle}>
      {item.image && (
        <div>
          <Image
            ref={imageRef}
            src={item.image_keyword}
            style={{
              ...style.imageStyle,
            }}
            slideDuration={(duration * length) + 1}
          />
        </div>
      )}
      <div className="flex items-center justify-start gap-8">
        <div
          ref={backgroundRef}
          className="w-1/3"
          style={{
            ...style.backgroundStyle,
          }}
        />
        <div className="overflow-hidden w-2/3 z-20 flex-auto">
          <Text ref={textRef} text={item.title} style={{ ...style.titleStyle, fontSize: `${fontSize}px` }} />
        </div>
      </div>
    </div>
  );
}

const TagsBullets = forwardRef<HTMLDivElement, ListTextProps>(
  ({ data, style, slideDuration }, ref) => {
    const { items, itemStyles, itemAnimations } = data;
    const singularDuration = ((slideDuration - 1) / items.length);
    const delays = useMemo(() => {
      let currentDelay = 0;
      return items.map(() => {
        const delayForThisItem = currentDelay;
        currentDelay += singularDuration;
        return delayForThisItem;
      });
    }, [data]);

    return (
      <div ref={ref} className={`${items[0]?.image && 'content-center'}`} style={style}>
        {items.map((item: any, index: any) => (
          <AnimatedItem
            key={index}
            item={item}
            duration={singularDuration}
            length={items.length}
            style={{
              parentStyle: {
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-around',
                height: `calc(${style.height} / ${items.length})`,
              },
              ...itemStyles,
            }}
            animations={itemAnimations}
            delay={delays[index]}
          />
        ))}
      </div>
    );
  },
);

TagsBullets.displayName = 'TagsBullets';
export default TagsBullets;
