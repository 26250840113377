import styled from "styled-components";

import Text from "./text";

const ListItem = styled.div`
		padding: 8px;
		background: #4C4100;
`;

export default (props: any) => {
	return (
		<div style={{...props.style}}>
			{props.list.map((text: string, index: number) => (
				<ListItem
					key={index}
					style={{
						marginBottom: index === props.list.length - 1 ? 0 : "8px",
					}}
				>
					<Text
						text={text}
						style={{
							"color": "#FFF4B7",
							"fontFamily": "Poppins",
							"fontWeight": "400",
							"fontSize": "10px",
							"lineHeight": "13px",
						}}
					/>
				</ListItem>
			))}
		</div>
	);
};
