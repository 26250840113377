import { Outlet } from 'react-router-dom';

import Header from './components/Header';

export default function Root() {
  return (
    <div className="h-screen flex sm:flex-col">
      <Header />
      <div className="h-full sm:h-[calc(100%-56px)] w-full m-auto max-w-screen-xl">
        <Outlet />
      </div>
    </div>
  );
}
