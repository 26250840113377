import { useEffect, useState } from "react";
import LicaPage from "../../../components/LicaPage";
import useFetch from "../../../hooks/useFetch";
import Chat from "./chat";
import { NIUM_DEMO, VIDEO_DATA } from "./video-data";

interface ContentRequestState {
    loading: boolean;
    loaded: boolean;
    loadingFailed: boolean;
    data: any;
    responseData: any;
}

export default function InteractiveDemo() {
    return (
        <LicaPage>
            <div className="flex px-20 w-full items-center justify-center h-[calc(100vh-128px)] bg-red-500">
                <Chat videoData={NIUM_DEMO} />
            </div>
        </LicaPage>
    )
}
