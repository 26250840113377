import { spring, useCurrentFrame, useVideoConfig } from "remotion";
import styled from "styled-components";
import { Icon } from "@iconify/react";

import Text from "./text";

const ListItem = styled.div`
    display: flex;
		align-items: center;
		padding: 8px;
    border-radius: 15px;
    background-color: #7575754e;
`;

const List = (props: {items: any[], style: any, itemStyle: any}) => {
	const frame = useCurrentFrame();
	const { fps } = useVideoConfig();

	const cardAnimations = props.items.map((_, index: number) => {
		const cardFrame = frame - index * 10; // Delay between each card
		const translateX = spring({
			from: -50,
			to: 0,
			frame: cardFrame,
			fps: fps * 3,
		});

		const opacity = spring({
			from: 0,
			to: 1,
			frame: cardFrame - index * 2,
			fps: fps * 3,
		});

		const translateXIcon = spring({
			from: -50,
			to: 0,
			frame: cardFrame,
			fps: fps * 4,
		});

		const opacityIcon = spring({
			from: 0,
			to: 1,
			frame: cardFrame - index * 2,
			fps: fps * 4,
		});

		return { translateX, opacity, translateXIcon, opacityIcon };
	});

	return (
		<div style={{...props.style}}>
			{props.items.map((content, index) => (
				<ListItem
					key={index}
					style={{
						marginBottom: index === props.items.length - 1 ? 0 : "8px",
						transform: `translateX(${cardAnimations[index].translateX}px)`,
						opacity: cardAnimations[index].opacity,
						...props.itemStyle,
					}}
				>
					<Icon height={20} icon="bi:check-circle-fill" />
					<Text
						text={content}
						style={{
							marginLeft: "8px",
							color: "white",
						}}
					/>
				</ListItem>
			))}
		</div>
	);
};

export default List;
