import { Spinner } from '@nextui-org/react';
import { useEffect, useState } from 'react';
import { prefetch } from 'remotion';

import useFetch from '@/hooks/useFetch';
import { getMediaLinks } from '@/utils/common';
import Video from './video';
import Podcast from './podcast';
import Blog from './blog';
import PodcastNew from './podcast-new';
import LoadingScreen from '../../components/LoadingScreen';

interface ContentRequestState {
  loading: boolean;
  loaded: boolean;
  loadingFailed: boolean;
  data: any;
  responseData: any;
}

function SingleContent({ contentId, contentType = 'VIDEO' }: { contentId: string, contentType: string }) {
  const [contentRequest, setContentRequest] = useState<ContentRequestState>({
    loading: true,
    loaded: false,
    loadingFailed: false,
    data: null,
    responseData: null,
  });
  const [isMediaCached, setIsMediaCached] = useState(false);
  const [showContent, setShowContent] = useState(false); // New state to control page load delay

  const { fetchData: getContent } = useFetch<any>(
    `${import.meta.env.VITE_BACKEND_URL}/api/v1/contents/${contentId}/`,
    { method: 'GET' },
  );

  const fetchContent = async () => {
    try {
      const responseData = await getContent();

      if (responseData.data.status === 'completed') {
        setContentRequest((prevState) => ({
          ...prevState,
          loading: false,
          loaded: true,
          data: responseData.data,
        }));
      } else if (responseData.data.status === 'failed') {
        setContentRequest((prevState) => ({
          ...prevState,
          loading: false,
          loaded: false,
          loadingFailed: true,
          data: null,
        }));
      } else {
        setTimeout(() => {
          fetchContent();
        }, 10000);
      }
    } catch (err) {
      setContentRequest((prevState) => ({
        ...prevState,
        loading: false,
        loaded: false,
        loadingFailed: true,
        data: null,
      }));
    }
  };

  useEffect(() => {
    if (contentRequest.loading && !contentRequest.data) {
      fetchContent().then(() => {
      });
    } else if (contentRequest.data) {
      const promises: any[] = [];

      const mediaLinks = getMediaLinks(contentRequest.data);
      const uniqueMediaLinks = new Set(mediaLinks);

      uniqueMediaLinks.forEach((link) => {
        promises.push(prefetch(link).waitUntilDone());
      });
      Promise.allSettled(promises).then(() => {
        setIsMediaCached(true);
      });
    }
  }, [contentRequest]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowContent(true);
    }, 5000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    showContent && contentRequest.loaded && isMediaCached ? (
      contentType === 'VIDEO' ? (
        <Video
          contentId={contentId}
          videoData={contentRequest.data.output.video_data}
          responseData={contentRequest.data}
        />
      ) : contentType === 'PODCAST' ? (
        contentRequest.data.output.version === 'v2' ? (
          <PodcastNew
            contentId={contentId}
            data={contentRequest.data}
          />
        ) : (
          <Podcast
            contentId={contentId}
            data={contentRequest.data}
          />
        )
      ) : contentType === 'BLOGPOST' ? (
        <Blog
          contentId={contentId}
          data={contentRequest.data.output}
          responseData={contentRequest.data}
        />
      ) : null
    ) : !contentRequest.loading && contentRequest.loadingFailed ? (
      <div className="h-full dark:text-secondary-dark text-secondary-light flex justify-center items-center">
        <div>
          Couldn't generate the content. Please try again.
        </div>
      </div>
    ) :
      <div
        className="flex justify-center flex-col gap-5 dark:text-secondary-dark text-secondary-light items-center w-full h-full"
        style={{ minHeight: 'inherit' }}
      >
        <LoadingScreen contentLoaded={contentRequest.loaded} totalSteps={5} />
      </div>
  );
}

export default SingleContent;