import React, { RefObject } from "react";
import { AbsoluteFill, Audio } from "remotion";
import { PlayerRef } from "@remotion/player";
import { TransitionSeries } from "@remotion/transitions";
import { Icon } from "@iconify/react";

import { AnimatedOld, Image } from "../comps";

const DemoTemplate3 = ({player}: {player: RefObject<PlayerRef>}) => {
	return (
		<AbsoluteFill className="flex justify-center items-center">
			<TransitionSeries>
				<TransitionSeries.Sequence durationInFrames={120}>
					<Audio loop={false} src="https://storage.googleapis.com/lica-video/test/demo-2-1.mp3" volume={1} />
					<AbsoluteFill>
						<AnimatedOld animation="wipe">
							<Image src="https://storage.googleapis.com/lica-video/test/demo-1.png" />
						</AnimatedOld>
						<div
							style={{
								background: "rgba(0, 0, 0, 0.5)",
								position: "absolute",
								top: 0,
								left: 0,
								width: "100%",
								height: "100%",
							}}
						/>
					</AbsoluteFill>
					<AbsoluteFill className="justify-center items-center">
						<AnimatedOld animation="fade">
							<div className="text-center" style={{color: "red", fontSize: "36px", fontWeight: "600", fontStyle: "italic"}}>Events <br/>In<br/> San Francisco</div>
						</AnimatedOld>
					</AbsoluteFill>
				</TransitionSeries.Sequence>
				<TransitionSeries.Sequence durationInFrames={90}>
					<Audio loop={false} src="https://storage.googleapis.com/lica-video/test/demo-2-2.mp3" volume={1} />
					<AbsoluteFill style={{backgroundColor: "black"}}>
						<AnimatedOld animation="wipe">
							<Image src="https://storage.googleapis.com/lica-video/test/demo-2.png" />
						</AnimatedOld>
					</AbsoluteFill>
					<AbsoluteFill className="justify-end items-center">
						<button
							className='bg-violet-900 text-white rounded-full text-large w-[180px] h-[60px] mb-20'
							onClick={() => {
								player.current?.pause();
								window.open("https://vercel.com/try/AI-Night-By-Vercel-SF", "_blank");
							}}
						>Register
						</button>
					</AbsoluteFill>
				</TransitionSeries.Sequence>
				<TransitionSeries.Sequence durationInFrames={240}>
					<Audio loop={false} src="https://storage.googleapis.com/lica-video/test/demo-2-2.mp3" volume={1} startFrom={90} />
					<AbsoluteFill style={{backgroundColor: "black"}}>
						<AnimatedOld animation="pan">
							<Image src="https://storage.googleapis.com/lica-video/test/demo-2.png"/>
						</AnimatedOld>
						<div
							style={{
								background: "rgba(0, 0, 0, 0.85)",
								position: "absolute",
								top: 0,
								left: 0,
								width: "100%",
								height: "100%",
							}}
						/>
					</AbsoluteFill>
					<AbsoluteFill className="justify-around items-center">
						<div style={{fontSize: "24px", color: "white"}}>AI night by Vercel</div>
						<div className="flex flex-col items-center">
							<AnimatedOld animation="rise">
								<div
									className="flex gap-2 items-center justify-center"
									style={{backgroundColor: "white", padding: "4px 10px", borderRadius: "20px"}}
								>
									<div>
										<Icon height={20} icon="solar:map-arrow-right-bold"/>
									</div>
									<div style={{color: "black", fontSize: "14px"}}>
										Network with developers
									</div>
								</div>
							</AnimatedOld>
							<div style={{height: "20px"}}/>
							<AnimatedOld animation="rise">
								<div
									className="flex gap-2 items-center justify-center"
									style={{backgroundColor: "white", padding: "4px 10px", borderRadius: "20px"}}
								>
									<div>
										<Icon height={20} icon="solar:map-arrow-right-bold"/>
									</div>
									<div style={{color: "black", fontSize: "14px"}}>
										Engage with your audience
									</div>
								</div>
							</AnimatedOld>
							<div style={{height: "20px"}}/>
							<AnimatedOld animation="rise">
								<div
									className="flex gap-2 items-center justify-center"
									style={{backgroundColor: "white", padding: "4px 10px", borderRadius: "20px"}}
								>
									<div>
										<Icon height={20} icon="solar:map-arrow-right-bold"/>
									</div>
									<div style={{color: "black", fontSize: "14px"}}>
										Network with practitioners
									</div>
								</div>
							</AnimatedOld>
							<div style={{height: "20px"}}/>
							<AnimatedOld animation="rise">
								<div
									className="flex gap-2 items-center justify-center"
									style={{backgroundColor: "white", padding: "4px 10px", borderRadius: "20px"}}
								>
									<div>
										<Icon height={20} icon="solar:map-arrow-right-bold"/>
									</div>
									<div style={{color: "black", fontSize: "14px"}}>
										Collaborate with others
									</div>
								</div>
							</AnimatedOld>
							<div style={{height: "20px"}}/>
							<AnimatedOld animation="rise">
								<div
									className="flex gap-2 items-center justify-center"
									style={{backgroundColor: "white", padding: "4px 10px", borderRadius: "20px"}}
								>
									<div>
										<Icon height={20} icon="solar:map-arrow-right-bold"/>
									</div>
									<div style={{color: "black", fontSize: "14px"}}>
										Analyze your data
									</div>
								</div>
							</AnimatedOld>
						</div>
						<button
							className='bg-violet-900 text-white rounded-full text-large w-[180px] h-[60px] mb-8'
							onClick={() => {
								player.current?.pause();
								window.open("https://vercel.com/try/AI-Night-By-Vercel-SF", "_blank");
							}}
						>Register
						</button>
					</AbsoluteFill>
				</TransitionSeries.Sequence>
				<TransitionSeries.Sequence durationInFrames={240}>
					<Audio loop={false} src="https://storage.googleapis.com/lica-video/test/demo-2-3.mp3" volume={1} />
					<AbsoluteFill>
						<AnimatedOld animation="wipe">
							<Image src="https://storage.googleapis.com/lica-video/test/demo-3.png"/>
						</AnimatedOld>
					</AbsoluteFill>
					<AbsoluteFill className="justify-end items-center">
						<button
							className='bg-black text-white rounded-full text-large w-[180px] h-[60px] mb-20'
							onClick={() => {
								player.current?.pause();
								window.open("https://pilot.com/event/founders-and-funders-ai-harrison-chase", "_blank");
							}}
						>Register
						</button>
					</AbsoluteFill>
				</TransitionSeries.Sequence>
			</TransitionSeries>
		</AbsoluteFill>
	);
};

export default DemoTemplate3;
