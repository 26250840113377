import React, { useMemo, useCallback, useContext } from 'react';
import gsap from 'gsap';
import { Button } from '@nextui-org/react';
import { Icon } from '@iconify/react';
import { PlayerRef } from '@remotion/player';
import NotesIcon from '@mui/icons-material/Notes';

import AuthContext from '@/auth/context';

interface ControlBarProps {
  podcastData: any;
  playerRef: React.RefObject<PlayerRef>;
  videoRef: React.RefObject<HTMLVideoElement>;
  isPlaying: boolean;
  isShuffling: boolean;
  handleShuffleClick: () => void;
  isDownloading: boolean;
  handleDownloadClick: () => void;
  showTranscript: boolean;
  setShowTranscript: (showTranscript: boolean) => void;
  data: any;
  contentId: string;
  width: number;
  navigate: any;
}

const ControlBar: React.FC<ControlBarProps> = ({
  podcastData,
  playerRef,
  videoRef,
  isPlaying,
  isShuffling,
  handleShuffleClick,
  isDownloading,
  handleDownloadClick,
  showTranscript,
  contentId,
  setShowTranscript,
  data,
  width,
  navigate,
}) => {
  const authContext = useContext(AuthContext);

  const durationMinutes = useMemo(
    () => Math.ceil(podcastData.duration / 60),
    [podcastData.duration],
  );
  const listenText = `${durationMinutes}-Minute Listen`;

  const playPodcast = useCallback(() => {
    const remotionPlayer = playerRef.current;
    const videoPlayer = videoRef.current;

    if (remotionPlayer) {
      if (isPlaying) {
        remotionPlayer.pause();
      } else {
        remotionPlayer.play();
      }
    }

    if (videoPlayer) {
      if (isPlaying) {
        videoPlayer.pause();
      } else {
        videoPlayer.play();
      }
    }
  }, [playerRef, videoRef, isPlaying]);

  async function shareHandler() {
    const currentUrl = window.location.href;
    const urlObj = new URL(currentUrl);
    urlObj.search = '';

    if (typeof navigator.share === 'function') {
      await navigator.share({
        title: 'Lica World',
        text: 'Check out what I created on Lica!',
        url: urlObj.toString(),
      });
    } else {
      gsap.set('.clipboard-message-control-bar', {
        opacity: 1,
      });
      gsap.to('.clipboard-message-control-bar', {
        opacity: 0,
        delay: 1,
      });
      await navigator.clipboard.writeText(urlObj.toString());
    }
  }

  function TranscriptButton() {
    const buttonStyleB = showTranscript
      ? 'font-xs rounded-full hover:opacity-90 dark:text-primary-dark text-primary-light bg-tertiary'
      : 'font-xs rounded-full hover:opacity-90 dark:text-primary-dark text-primary-light bg-tertiary';

    function onClick() {
      setShowTranscript(!showTranscript);
    }

    return (
      <button
        className={`${buttonStyleB} flex dark:text-primary-dark text-primary-light p-2 text-xs justify-center`}
        onClick={onClick}
      >
        <NotesIcon />
        <div className="m-1">Transcript</div>
      </button>
    );
  }

  return (
    <div className="w-full border border-secondary border-opacity-40 justify-between rounded-full px-2 pt-1 flex sm:gap-0 gap-2 items-center">
      <div className="p-2 flex items-center sm:gap-0 gap-3">
        <Icon
          icon={
            isPlaying ? 'solar:pause-circle-bold' : 'solar:play-circle-bold'
          }
          className="w-10 h-10 cursor-pointer text-tertiary"
          onClick={playPodcast}
          aria-label={isPlaying ? 'Pause Podcast' : 'Play Podcast'}
        />
        {width > 600 && !showTranscript
          && <div className="p-2 font-normal sm:text-small dark:text-secondary-dark text-secondary-light">{listenText}</div>}
      </div>
      <div className="p-2 flex sm:gap-0 gap-3">
        <div className="flex gap-2 relative">
          {data.user_info.email === authContext.currentUser?.email && (
            <>
              <Button
                isLoading={isShuffling}
                isIconOnly
                size="md"
                color="secondary"
                radius="full"
                variant="light"
                onClick={handleShuffleClick}
              >
                <Icon className="!text-tertiary hover:!dark:text-secondary-dark text-secondary-light" width={25} icon="ph:shuffle-bold" />
              </Button>
              <Button
                isIconOnly
                size="md"
                color="secondary"
                radius="full"
                variant="light"
                onClick={() => navigate(`/content/${contentId}/edit`)}
              >
                <Icon className="!text-tertiary hover:!dark:text-secondary-dark text-secondary-light" width={25} icon="iconamoon:edit" />
              </Button>
            </>
          )}
        </div>
        <Button
          className="text-small bg-tertiary dark:text-primary-dark text-primary-light"
          radius="full"
          startContent={
            !isDownloading ? (
              <Icon icon="mdi:download" className="text-base" />
            ) : null
          }
          isLoading={isDownloading}
          onClick={handleDownloadClick}
        >
          Download
        </Button>
        {width > 700 && <TranscriptButton />}
      </div>
    </div>
  );
};

export default ControlBar;
