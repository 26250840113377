import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { prefetch } from 'remotion';
import { Spacer, Spinner } from '@nextui-org/react';

import AuthContext from '@/auth/context';
import LicaContent from './components/content';

function LicaPage() {
  const authContext = useContext(AuthContext);
  const urlParams = useParams();

  const [
    pageResponse,
    setPageResponse,
  ] = useState<{ loading: boolean, loaded: boolean, loadingFailed: boolean, data: any }>(
    {
      loading: true, loaded: false, loadingFailed: false, data: null,
    },
  );
  const [isMediaCached, setIsMediaCached] = useState(false);

  const fetchPage = async () => {
    const token = await authContext.currentUser?.getIdToken();
    if (!token) return;

    fetch(`${import.meta.env.VITE_BACKEND_URL}/api/v1/pages/${urlParams.pageId}/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((responseData) => {
        if (responseData.data.status == 'processing' || responseData.data.status == 'completed') {
          setPageResponse((prevState) => ({
            ...prevState,
            loading: false,
            loaded: true,
            data: responseData.data.output,
          }));
        } else if (responseData.data.status == 'failed') {
          setPageResponse((prevState) => ({
            ...prevState,
            loading: false,
            loaded: false,
            loadingFailed: true,
            data: null,
          }));
        } else {
          setTimeout(() => {
            fetchPage();
          }, 10000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function getMediaLinks(data: any) {
    let links: string[] = [];
    for (const key in data) {
      const val = data[key];

      if (val === null) continue;

      if (typeof val === 'object') {
        links = links.concat(getMediaLinks(val));
      } else if (Array.isArray(val)) {
        val.forEach((obj: any) => {
          links = links.concat(getMediaLinks(obj));
        });
      } else if (typeof val === 'string') {
        try {
          const url = new URL(val);
          const isMedia = /\.(mp4|webm|jpg|jpeg|svg|png|webp|avif|gif|mp3)$/.test(url.pathname);
          if (isMedia) {
            links.push(val);
          }
        } catch (e) { /* empty */ }
      }
    }
    return links;
  }

  useEffect(() => {
    if (pageResponse.loading && !pageResponse.data) {
      fetchPage().then(() => {});
    } else if (pageResponse.data) {
      const promises: any[] = [];
      const mediaLinks = getMediaLinks(pageResponse.data);
      mediaLinks.forEach((link) => {
        promises.push(prefetch(link).waitUntilDone());
      });
      Promise.allSettled(promises).then(() => {
        setIsMediaCached(true);
      });
    }
  }, [authContext, pageResponse]);

  const isPageReady = () => pageResponse.loaded && isMediaCached;

  return (
    <div className="p-4 h-full">
      {isPageReady() ? (
        <div className="flex flex-col h-full items-center overflow-scroll">
          <div style={{ color: '#B6B6B6' }}>
            {pageResponse.data?.title}
          </div>
          <Spacer y={4} />
          <div className="gap-2">
            {pageResponse.data.content_ids.map((contentId: string) => (
              <LicaContent key={contentId} contentId={contentId} />
            ))}
          </div>
          <Spacer y={6} />
        </div>
      ) : (!pageResponse.loading && pageResponse.loadingFailed) ? (
        <div className="flex items-center justify-center h-full">
          <div style={{ color: '#B6B6B6' }}>Couldn't generate the video. Please try again.</div>
        </div>
      ) : (
        <div className="flex items-center justify-center h-full">
          <Spinner color="default" />
        </div>
      )}
    </div>
  );
}

export default LicaPage;
