import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { prefetch } from 'remotion';
import { Spacer, Spinner } from '@nextui-org/react';

import AuthContext from '@/auth/context';
import Audio from './audio';
import Text from './text';
import Video from './video';
import VideoHighlights from './video-highlights';

function V2OutputPage() {
  const authContext = useContext(AuthContext);
  const urlParams = useParams();

  const [
    videoRequest,
    setVideoRequest,
  ] = useState<{ loading: boolean, loaded: boolean, loadingFailed: boolean, data: any, contentType: any }>(
    {
      loading: true, loaded: false, loadingFailed: false, data: null, contentType: null,
    },
  );
  const [isMediaCached, setIsMediaCached] = useState(false);

  const fetchVideo = async () => {
    const token = await authContext.currentUser?.getIdToken();

    fetch(`${import.meta.env.VITE_BACKEND_URL}/api/v1/video-requests/${urlParams.videoId}/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        }
        return response.json();
      })
      .then((responseData) => {
        if (responseData.data.status == 'completed') {
          setVideoRequest((prevState) => ({
            ...prevState,
            loading: false,
            loaded: true,
            contentType: responseData.data.content_type,
            data: responseData.data.output,
          }));
        } else if (responseData.data.status == 'failed') {
          setVideoRequest((prevState) => ({
            ...prevState,
            loading: false,
            loaded: false,
            loadingFailed: true,
            contentType: null,
            data: null,
          }));
        } else {
          setTimeout(() => {
            fetchVideo();
          }, 10000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function getMediaLinks(data: any) {
    let links: string[] = [];
    for (const key in data) {
      const val = data[key];

      if (val === null) continue;

      if (typeof val === 'object') {
        links = links.concat(getMediaLinks(val));
      } else if (Array.isArray(val)) {
        val.forEach((obj: any) => {
          links = links.concat(getMediaLinks(obj));
        });
      } else if (typeof val === 'string') {
        try {
          const url = new URL(val);
          const isMedia = /\.(mp4|webm|jpg|jpeg|svg|png|webp|avif|gif|mp3)$/.test(url.pathname);
          if (isMedia) {
            links.push(val);
          }
        } catch (e) { /* empty */ }
      }
    }
    return links;
  }

  useEffect(() => {
    if (videoRequest.loading && !videoRequest.data) {
      fetchVideo().then(() => {});
    } else if (videoRequest.data) {
      const promises: any[] = [];
      const mediaLinks = getMediaLinks(videoRequest.data);
      mediaLinks.forEach((link) => {
        promises.push(prefetch(link).waitUntilDone());
      });
      Promise.allSettled(promises).then(() => {
        setIsMediaCached(true);
      });
    }
  }, [authContext, videoRequest]);

  const isVideoReady = () => videoRequest.loaded && isMediaCached;

  const renderCurrentMode = () => {
    if (videoRequest.contentType === 'video') {
      return (
        <Video videoData={videoRequest.data.video_data} />
      );
    }

    if (videoRequest.contentType === 'audio') {
      return (
        <Audio audioData={videoRequest.data.audio_data} />
      );
    }

    if (videoRequest.contentType === 'text') {
      return (
        <Text textData={videoRequest.data.text_data.text} />
      );
    }

    if (videoRequest.contentType === 'video_highlights') {
      return (
        <VideoHighlights data={videoRequest.data.gcp_links} />
      );
    }
  };

  return (
    <div className="p-4 h-full">
      {isVideoReady() ? (
        <div className="flex flex-col h-full items-center overflow-hidden">
          <div style={{ color: '#B6B6B6' }}>
            {videoRequest.data?.title}
          </div>
          <Spacer y={6} />
          {renderCurrentMode()}
        </div>
      ) : (!videoRequest.loading && videoRequest.loadingFailed) ? (
        <div className="flex items-center justify-center h-full">
          <div style={{ color: '#B6B6B6' }}>Couldn't generate the video. Please try again.</div>
        </div>
      ) : (
        <div className="flex items-center justify-center h-full">
          <Spinner color="default" />
        </div>
      )}
    </div>
  );
}

export default V2OutputPage;
