import {
  forwardRef, useRef, useLayoutEffect, useState, useMemo, CSSProperties,
} from 'react';
import { useGsapTimeline } from '../animation/gsap';
import { AnimationFactory } from '../animation/animation';

interface TitlePopupProps {
  text: any;
  itemStyles?: CSSProperties;
  style?: CSSProperties;
  animation?: any;
  slideDuration: number;
}

const useItemAnimations = (textRef: any, animation: any, duration: number, delay: number) => {
  useGsapTimeline(() => {
    const animationFactory = AnimationFactory.createAnimation('expand');
    const attributes = animation?.attributes || {};
    const { gsapTimeline } = animationFactory.createTimeline(textRef.current, {
      stagger: 0.1,
      direction: 'left',
      expandHeight: true,
      opacity: 1,
      ...attributes,
      duration,
      delay,
    });
    return gsapTimeline;
  });
};

function AnimatedItem({
  text, delay, itemStyles, animation, duration,
}: { text: string; delay: number; itemStyles: any, animation: any, duration: number }) {
  const textRef = useRef<HTMLDivElement>(null);
  const values = itemStyles.padding ? itemStyles.padding.split(' ') : '0';
  const isDivideLines = animation?.attributes?.divider !== 'words';

  function calculateBestWidth(textValue: string): number {
    // Create a temporary span element
    const span = document.createElement('span');
    span.style.fontSize = `${parseFloat(itemStyles.fontSize)}px`;
    span.style.fontFamily = itemStyles.fontFamily ? itemStyles.fontFamily : 'Haffer';
    span.style.position = 'absolute';
    span.style.visibility = 'hidden';
    span.style.whiteSpace = 'nowrap';
    span.style.padding = `0 ${parseFloat(values[values.length - 1])}px`;
    span.innerText = textValue;

    document.body.appendChild(span);
    const textWidth = span.offsetWidth;
    document.body.removeChild(span);

    return textWidth;
  }

  useLayoutEffect(() => {
    if (textRef.current) {
      textRef.current.style.width = `${calculateBestWidth(text)}px`;
    }
  }, []);

  useItemAnimations(textRef, animation, duration, delay);

  return (
    <div
      className="overflow-hidden"
      ref={textRef}
      style={{
        display: 'flex', alignItems: 'center', whiteSpace: isDivideLines && 'nowrap', ...itemStyles,
      }}
    >
      {text}
    </div>
  );
}

const TitlePopup = forwardRef<HTMLDivElement, TitlePopupProps>(({
  text, style, itemStyles, animation, slideDuration,
}, ref) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const measureRef = useRef<HTMLDivElement>(null);
  const [lines, setLines] = useState<string[]>([]);
  const singularDuration = (
    (slideDuration - 1) / lines.length
  ) > 4 ? 4 : (slideDuration - 1) / lines.length;

  const delays = useMemo(() => {
    let currentDelay = 0;
    return lines.map(() => {
      const delayForThisItem = currentDelay;
      currentDelay += singularDuration;
      return delayForThisItem;
    });
  }, [lines, singularDuration]);

  const calculateLines = () => {
    if (containerRef.current && measureRef.current && text) {
      const tempLines: string[] = [];
      if (animation?.attributes?.divider === 'words') {
        const words = text.split(' ');
        words.forEach((word: any) => {
          tempLines.push(word);
        });
      } else {
        text?.forEach((word: any) => {
          tempLines.push(word);
        });
      }
      setLines(tempLines);
    }
  };

  useLayoutEffect(() => {
    if (!Array.isArray(text)) return;

    calculateLines();
  }, [text, itemStyles, animation?.attributes?.divider]);

  return (
    <div
      ref={ref}
      style={{
        display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', overflow: 'hidden', ...style,
      }}
    >
      {/* Main text container */}
      <div
        ref={containerRef}
        style={{
          display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%',
        }}
      >
        {/* Hidden div for measuring line width */}
        <div
          ref={measureRef}
          style={{
            position: 'absolute', visibility: 'hidden', whiteSpace: 'nowrap', ...itemStyles,
          }}
        />
        {lines.map((line, index) => (
          <AnimatedItem
            key={Math.random()}
            duration={singularDuration}
            delay={delays[index]}
            text={line}
            animation={animation}
            itemStyles={{
              ...itemStyles,
            }}
          />
        ))}
      </div>
    </div>
  );
});

TitlePopup.displayName = 'TitlePopup';

export default TitlePopup;
