import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@nextui-org/react';

import { auth } from '@/auth/firebase';
import { Icon } from '@iconify/react';

function LogoutButton() {
  const navigate = useNavigate();

  const onClick = () => {
    auth.signOut().then(() => {
      navigate('/login');
    });
  };

  return (
    <Button
      size="md"
      className="!text-primary-light bg-none bg-opacity-0 duration-500 text-sm"
      onClick={onClick}
    >
      <Icon className="" width={17} icon="ic:outline-logout" />
      Logout
    </Button>
  );
}

export default LogoutButton;
