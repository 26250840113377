import React from "react";
import { Input, Spacer } from "@nextui-org/react";

import styles from "./index.module.css";

interface IProps {
	controls: any;
	setControls: React.Dispatch<React.SetStateAction<any>>;
	showTitle: boolean;
}

const Blog = ({controls, setControls, showTitle}: IProps) => {

	const setStylePrompt = (stylePrompt: string) => {
		setControls({
			...controls,
			stylePrompt: stylePrompt,
		});
	};

	return (
		<div className={styles.controlContainer}>
			{showTitle ? (
				<>
					<label className={styles.label} htmlFor="blog-input">For Blog</label>
					<Spacer y={3}/>
				</>
			): null}
			<Input
				value={controls.stylePrompt}
				classNames={{
					input: ["bg-[#F9FAFB]"],
					inputWrapper: ["data-[hover=true]:bg-[#F9FAFB] group-data-[focus=true]:bg-[#F9FAFB] bg-[#F9FAFB] border-1 border-[#EAECF0]"],
				}}
				radius='sm'
				placeholder='Input Prompt to indicate style, tone'
				onChange={(e) => setStylePrompt(e.target.value)}
			/>
		</div>
	);
};

export default Blog;
