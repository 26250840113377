import { Img } from 'remotion';
import Video from './video';
import Image from './image';
import { useRef } from 'react';

function Background({
  image, video, mask, imageStyle, slideDuration
}: {
  image?: string,
  video?: string,
  mask?: string,
  slideDuration: number,
  imageStyle?: { tint?: string, blendMode?: any, exposure?: string, contrast?: string }
}) {

  const imageRef = useRef<any>(null);

  return (
    <div style={{
      height: '100%', width: '100%', position: 'absolute', zIndex: -1,
    }}
    >
      {imageStyle?.tint && (
        <div
          style={{
            background: imageStyle?.tint,
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
          }}
        />
      )}
      {image ? (
        <Image
          slideDuration={slideDuration}
          ref={imageRef}
          className="w-full h-full"
          src={image}
          style={{
            width: "100%",
            height: "100%",
            mixBlendMode: imageStyle?.blendMode,
            filter: `brightness(${imageStyle?.exposure}) contrast(${imageStyle?.contrast})`,
          }}
        />
      ) : video ? (
        <Video src={video} />
      ) : null}
      {mask && (
        <div
          style={{
            background: mask,
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
          }}
        />
      )}
    </div>
  );
}

export default Background;
