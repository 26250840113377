import React, { useState, RefObject } from "react";
import { AbsoluteFill, Audio } from "remotion";
import { PlayerRef } from "@remotion/player";
import { TransitionSeries } from "@remotion/transitions";
import { Icon } from "@iconify/react";

import { AnimatedOld, Image } from "../comps";

const DemoTemplate2 = ({player}: {player: RefObject<PlayerRef>}) => {
	const [displayText, setDisplayText] = useState(false);
	const [displayText2, setDisplayText2] = useState(false);

	return (
		<AbsoluteFill className="flex justify-center items-center">
			<TransitionSeries>
				<TransitionSeries.Sequence durationInFrames={150}>
					<Audio loop={false} src="https://storage.googleapis.com/lica-video/test/audio_1.mp3" volume={1} />
					<AbsoluteFill className="justify-center items-center" style={{backgroundColor:"#F3F0EC"}}>
						<div style={{fontSize: "24px"}}>Lica January Updates</div>
						<div style={{height: "20px"}} />
						<div className="flex gap-8">
							<div >
								<AnimatedOld animation="rise">
									<Image style={{width: "100px", height: "150px"}} src="https://storage.googleapis.com/lica-video/test/2.png"/>
								</AnimatedOld>
							</div>
							<div>
								<AnimatedOld animation="rise">
									<Image style={{width: "100px", height: "150px"}} src="https://storage.googleapis.com/lica-video/test/3.png"/>
								</AnimatedOld>
							</div>
							<div>
								<AnimatedOld animation="rise">
									<Image style={{width: "100px", height: "150px"}} src="https://storage.googleapis.com/lica-video/test/1.png"/>
								</AnimatedOld>
							</div>
						</div>
					</AbsoluteFill>
				</TransitionSeries.Sequence>
				<TransitionSeries.Sequence durationInFrames={300}>
					<Audio loop={false} src="https://storage.googleapis.com/lica-video/test/audio_2.mp3" volume={1} />
					{displayText ? (
						<div className="p-4 overflow-scroll">
							<h1 className='text-sm text-orange-300 pb-8'>Why AI Can Make Everything We Care About Better</h1>
							<p className='text-white text-sm leading-normal pb-8'>The most validated core conclusion of social
								science
								across
								many decades and thousands of studies is that human intelligence makes a very broad range of life
								outcomes
								better. Smarter people have better outcomes in almost every domain of activity: academic achievement,
								job
								performance, occupational status, income, creativity, physical health, longevity, learning new skills,
								managing complex tasks, leadership, entrepreneurial success, conflict resolution, reading
								comprehension,
								financial decision making, understanding others’ perspectives, creative arts, parenting outcomes, and
								life
								satisfaction.

								Further, human intelligence is the lever that we have used for millennia to create the world we live
								in
								today:
								science, technology, math, physics, chemistry, medicine, energy, construction, transportation,
								communication,
								art, music, culture, philosophy, ethics, morality. Without the application of intelligence on all
								these
								domains, we would all still be living in mud huts, scratching out a meager existence of subsistence
								farming.
								Instead we have used our intelligence to raise our standard of living on the order of 10,000X over the
								last
								4,000 years.

								What AI offers us is the opportunity to profoundly augment human intelligence to make all of these
								outcomes
								of
								intelligence – and many others, from the creation of new medicines to ways to solve climate change to
								technologies to reach the stars – much, much better from here.

								AI augmentation of human intelligence has already started – AI is already around us in the form of
								computer
								control systems of many kinds, is now rapidly escalating with AI Large Language Models like ChatGPT,
								and
								will
								accelerate very quickly from here – if we let it.
								In contrast to this positive view, the public conversation about AI is presently shot through with
								hysterical
								fear and paranoia.

								We hear claims that AI will variously kill us all, ruin our society, take all our jobs, cause
								crippling
								inequality, and enable bad people to do awful things.

								What explains this divergence in potential outcomes from near utopia to horrifying dystopia?

								Historically, every new technology that matters, from electric lighting to automobiles to radio to the
								Internet, has sparked a moral panic – a social contagion that convinces people the new technology is
								going
								to
								destroy the world, or society, or both. The fine folks at Pessimists Archive have documented these
								technology-driven moral panics over the decades; their history makes the pattern vividly clear. It
								turns out
								this present panic is not even the first for AI.

								Now, it is certainly the case that many new technologies have led to bad outcomes – often the same
								technologies that have been otherwise enormously beneficial to our welfare. So it’s not that the mere
								existence of a moral panic means there is nothing to be concerned about.

								But a moral panic is by its very nature irrational – it takes what may be a legitimate concern and
								inflates
								it
								into a level of hysteria that ironically makes it harder to confront actually serious concerns.

								And wow do we have a full-blown moral panic about AI right now.

								This moral panic is already being used as a motivating force by a variety of actors to demand policy
								action
								–
								new AI restrictions, regulations, and laws. These actors, who are making extremely dramatic public
								statements
								about the dangers of AI – feeding on and further inflaming moral panic – all present themselves as
								selfless
								champions of the public good.

								But are they?

								And are they right or wrong?
							</p>
							<div className="flex justify-center items-center mb-16">
								<button
									className='bg-red-200 rounded-3xl hover:bg-green-200 text-sm w-[120px] h-[40px]'
									onClick={() => {
										player.current?.play();
										player.current?.seekTo(601);
										setTimeout(() => {
											setDisplayText(false);
										}, 2000);
									}}
								>Continue
								</button>
							</div>
						</div>
					) : (
						<AbsoluteFill className="flex justify-center items-center" style={{backgroundColor: "#F3F0EC"}}>
							<div className="flex gap-8">
								<div className="flex-auto">
									<AnimatedOld animation="rise">
										<Image
											style={{width: "100px", height: "150px"}}
											src="https://storage.googleapis.com/lica-video/test/2.png"
										/>
									</AnimatedOld>
									<div style={{height: "20px"}}/>
									<AnimatedOld animation="rise">
										<div>
											<button
												className='bg-black text-white rounded-3xl text-small w-[100px] h-[30px]'
												onClick={() => {
													setDisplayText(true);
													player.current?.pause();
												}}
											>Read More
											</button>
										</div>
									</AnimatedOld>
								</div>
								<div className="flex-auto">
									<div className="flex gap-2 items-center">
										<div>
											<Icon height={36} icon="solar:map-arrow-right-bold"/>
										</div>
										<div style={{width: "210px"}}>
											<AnimatedOld animation="pan">
												<div>
													Added editing capabilities
												</div>
											</AnimatedOld>
										</div>
									</div>
									<div style={{height: "20px"}}/>
									<div className="flex gap-2 items-center">
										<div>
											<Icon height={36} icon="solar:map-arrow-right-bold"/>
										</div>
										<div style={{width: "210px"}}>
											<AnimatedOld animation="pan">
												<div>
													Added video input support
												</div>
											</AnimatedOld>
										</div>
									</div>
									<div style={{height: "20px"}}/>
									<div className="flex gap-2 items-center">
										<div>
											<Icon height={36} icon="solar:map-arrow-right-bold"/>
										</div>
										<div style={{width: "210px"}}>
											<AnimatedOld animation="pan">
												<div>
													Condense multiple URLs into one form
												</div>
											</AnimatedOld>
										</div>
									</div>
								</div>
							</div>
						</AbsoluteFill>
					)}
				</TransitionSeries.Sequence>
				<TransitionSeries.Sequence durationInFrames={300}>
					<Audio loop={false} src="https://storage.googleapis.com/lica-video/test/audio_3.mp3" volume={1} />
					{displayText2 ? (
						<div className="p-4 overflow-scroll">
							<h1 className='text-sm text-orange-300 pb-8'>Why AI Can Make Everything We Care About Better</h1>
							<p className='text-white text-sm leading-normal pb-8'>The most validated core conclusion of social
								science
								across
								many decades and thousands of studies is that human intelligence makes a very broad range of life
								outcomes
								better. Smarter people have better outcomes in almost every domain of activity: academic achievement,
								job
								performance, occupational status, income, creativity, physical health, longevity, learning new skills,
								managing complex tasks, leadership, entrepreneurial success, conflict resolution, reading
								comprehension,
								financial decision making, understanding others’ perspectives, creative arts, parenting outcomes, and
								life
								satisfaction.

								Further, human intelligence is the lever that we have used for millennia to create the world we live
								in
								today:
								science, technology, math, physics, chemistry, medicine, energy, construction, transportation,
								communication,
								art, music, culture, philosophy, ethics, morality. Without the application of intelligence on all
								these
								domains, we would all still be living in mud huts, scratching out a meager existence of subsistence
								farming.
								Instead we have used our intelligence to raise our standard of living on the order of 10,000X over the
								last
								4,000 years.

								What AI offers us is the opportunity to profoundly augment human intelligence to make all of these
								outcomes
								of
								intelligence – and many others, from the creation of new medicines to ways to solve climate change to
								technologies to reach the stars – much, much better from here.

								AI augmentation of human intelligence has already started – AI is already around us in the form of
								computer
								control systems of many kinds, is now rapidly escalating with AI Large Language Models like ChatGPT,
								and
								will
								accelerate very quickly from here – if we let it.
								In contrast to this positive view, the public conversation about AI is presently shot through with
								hysterical
								fear and paranoia.

								We hear claims that AI will variously kill us all, ruin our society, take all our jobs, cause
								crippling
								inequality, and enable bad people to do awful things.

								What explains this divergence in potential outcomes from near utopia to horrifying dystopia?

								Historically, every new technology that matters, from electric lighting to automobiles to radio to the
								Internet, has sparked a moral panic – a social contagion that convinces people the new technology is
								going
								to
								destroy the world, or society, or both. The fine folks at Pessimists Archive have documented these
								technology-driven moral panics over the decades; their history makes the pattern vividly clear. It
								turns out
								this present panic is not even the first for AI.

								Now, it is certainly the case that many new technologies have led to bad outcomes – often the same
								technologies that have been otherwise enormously beneficial to our welfare. So it’s not that the mere
								existence of a moral panic means there is nothing to be concerned about.

								But a moral panic is by its very nature irrational – it takes what may be a legitimate concern and
								inflates
								it
								into a level of hysteria that ironically makes it harder to confront actually serious concerns.

								And wow do we have a full-blown moral panic about AI right now.

								This moral panic is already being used as a motivating force by a variety of actors to demand policy
								action
								–
								new AI restrictions, regulations, and laws. These actors, who are making extremely dramatic public
								statements
								about the dangers of AI – feeding on and further inflaming moral panic – all present themselves as
								selfless
								champions of the public good.

								But are they?

								And are they right or wrong?
							</p>
							<div className="flex justify-center items-center mb-16">
								<button
									className='bg-red-200 rounded-3xl hover:bg-green-200 text-sm w-[120px] h-[40px]'
									onClick={() => {
										player.current?.play();
										setDisplayText2(false);
									}}
								>Continue
								</button>
							</div>
						</div>
					) : (
						<AbsoluteFill className="flex justify-center items-center" style={{backgroundColor: "#F3F0EC"}}>
							<div className="flex gap-8">
								<div className="flex-auto">
									<AnimatedOld animation="rise">
										<Image
											style={{width: "100px", height: "150px"}}
											src="https://storage.googleapis.com/lica-video/test/3.png"
										/>
									</AnimatedOld>
									<div style={{height: "20px"}}/>
									<AnimatedOld animation="rise">
										<button
											className='bg-black text-white rounded-3xl text-small w-[100px] h-[30px]'
											onClick={() => {
												setDisplayText2(true);
												player.current?.pause();
											}}
										>Read More
										</button>
									</AnimatedOld>
								</div>
								<div className="flex-auto">
									<div className="flex gap-2 items-center">
										<div>
											<Icon height={36} icon="solar:map-arrow-right-bold"/>
										</div>
										<div style={{width: "210px"}}>
											<AnimatedOld animation="pan">
												<div>
													Lip sync capabilities on typing the name
												</div>
											</AnimatedOld>
										</div>
									</div>
									<div style={{height: "20px"}}/>
									<div className="flex gap-2 items-center">
										<div>
											<Icon height={36} icon="solar:map-arrow-right-bold"/>
										</div>
										<div style={{width: "210px"}}>
											<AnimatedOld animation="pan">
												<div>
													Added meme support
												</div>
											</AnimatedOld>
										</div>
									</div>
									<div style={{height: "20px"}}/>
									<div className="flex gap-2 items-center">
										<div>
											<Icon height={36} icon="solar:map-arrow-right-bold"/>
										</div>
										<div style={{width: "210px"}}>
											<AnimatedOld animation="pan">
												<div>
													Funny cartoon narration
												</div>
											</AnimatedOld>
										</div>
									</div>
								</div>
							</div>
						</AbsoluteFill>
					)}
				</TransitionSeries.Sequence>
			</TransitionSeries>
		</AbsoluteFill>
	);
};

export default DemoTemplate2;
