import classNames from 'classnames';
import { FC, useEffect, useState } from 'react';

const ProgressList: FC<{ items: string[]; onFinish: () => void }> = ({
  items,
  onFinish,
}) => {
  const [currentRunning, setCurrentRunning] = useState<number>(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentRunning((prev) => {
        if (prev === items.length - 1) {
          clearInterval(interval);
          onFinish();
          return prev;
        }
        return prev + 1;
      });
    }, 3000);

    return () => {
      clearInterval(interval);
    };
  }, [onFinish]);

  return (
    <div>
      {items.map((item, index) => (
        <div key={index} className="flex items-center my-2">
          {currentRunning > index ? (
            <div className="w-2 h-2 rounded-full border border-gray-400 bg-purple-600" />
          ) : (
            <div className="w-2 h-2 rounded-full border border-gray-400 flex items-center justify-center" />
          )}
          <p
            className={classNames('mx-2 text-base', {
              'text-gray-400': currentRunning <= index,
            })}
          >
            {item}
          </p>
          {currentRunning === index && (
            <div className="border-gray-300 h-5 w-5 animate-spin rounded-full border border-t-purple-600" />
          )}
        </div>
      ))}
    </div>
  );
};

export default ProgressList;
