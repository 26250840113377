import React, {
  useState, useRef, useContext, useEffect,
} from 'react';
import { Button, Spacer } from '@nextui-org/react';
import { Icon } from '@iconify/react';
import AuthContext from '@/auth/context';
import useFetch from '@/hooks/useFetch';
import LicaPage from '@/components/LicaPage';

import styles from './index.module.css';

function FluxPage() {
  const [prompt, setPrompt] = useState('');
  const [imageURL, setImageURL] = useState('');
  const [selectedFile, setSelectedFile] = useState<File | null>(null); // State for a single file
  const [
    imagePreview,
    setImagePreview,
  ] = useState<string | null>(null); // State for a single image preview
  const [
    generatedImageUrl,
    setGeneratedImageUrl,
  ] = useState<string | null>(null); // State for generated image URL
  const [isGenerating, setIsGenerating] = useState(false);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const authContext = useContext(AuthContext);

  const {
    data: getData,
    triggerFetch: triggerGetFetch,
  } = useFetch<any>(`${import.meta.env.VITE_BACKEND_URL}/api/v1/content-options/`, { method: 'GET' });

  useEffect(() => {
    triggerGetFetch();
  }, [getData]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null; // Only take the first file
    if (file && file.type.startsWith('image/')) {
      setSelectedFile(file);
      setImagePreview(URL.createObjectURL(file));
    }
  };

  const handleRemoveImage = () => {
    setImagePreview(null);
    setSelectedFile(null);
  };

  const handleSubmit = async () => {
    if (!prompt && !imageURL) return;

    setIsGenerating(true);

    try {
      const formData = new FormData();

      // Append prompt and image URL to the form data
      const data = JSON.stringify({
        prompt,
        image_url: imageURL,
        content_types: ['image'], // Add more content types if necessary
      });
      formData.append('data', data);

      // Append the selected file to the form data, if a file was selected
      if (selectedFile) {
        formData.append('files', selectedFile);
      }

      const token = await authContext.currentUser?.getIdToken();

      const response = await fetch(`${import.meta.env.VITE_BACKEND_URL}/api/v1/flux/upload/`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      const result = await response.json();

      // Set the generated image URL to state after receiving the response
      const imageUrl = result.images[0].url;
      setGeneratedImageUrl(imageUrl);
    } finally {
      setIsGenerating(false);
    }
  };

  return (
    <LicaPage>
      <div className={styles.wrapper}>
        <div
          className={`${styles.container} flex border border-secondary border-opacity-20 flex-col items-center justify-center max-w-none rounded-[3rem] p-4`}
        >
          <Spacer y={3} />
          <div className={`${styles.yourStoryContainer}`}>
            <textarea
              value={prompt}
              className={`${styles.yourStoryInput} bg-transparent w-full resize-none border border-secondary border-opacity-20 p-4 outline-none min-h-36 rounded-2xl`}
              style={{
                background: 'transparent',
              }}
              placeholder="Enter your story here"
              onChange={(e) => setPrompt(e.target.value)}
              id="your-story-input"
            />
            <textarea
              value={imageURL}
              className={`${styles.yourStoryInput} bg-transparent w-full resize-none border border-secondary border-opacity-20 p-4 outline-none rounded-2xl`}
              style={{
                background: 'transparent',
              }}
              placeholder="Enter an image URL here"
              onChange={(e) => setImageURL(e.target.value)}
              id="your-url-input"
            />
            <div>
              <label
                htmlFor="file-upload"
                className={styles.imageUploadIcon}
              >
                <Icon
                  icon="tdesign:attach"
                  className="text-secondary opacity-70"
                  style={{ fontSize: '1.25rem', cursor: 'pointer' }}
                />
              </label>
              <input
                type="file"
                id="file-upload"
                className={styles.imageUploadInput}
                onChange={handleFileChange}
                ref={fileInputRef}
                accept=".jpg,.jpeg,.png,.webp,.tiff,.svg"
              />
            </div>
            {/* Image preview */}
            {imagePreview && (
              <div className={styles.imagePreviewContainer}>
                <img
                  src={imagePreview}
                  alt="Preview"
                  className={styles.imagePreview}
                />
                <button
                  type="button"
                  onClick={handleRemoveImage}
                  className={styles.removeButton}
                >
                  <Icon icon="carbon:close" style={{ fontSize: '1rem', color: 'black' }} />
                </button>
              </div>
            )}
          </div>
          <Spacer y={3} />
          <Button
            radius="full"
            color="primary"
            className="w-36 h-12 mt-5 text-lg font-medium text-primary hover:bg-secondary bg-tertiary"
            onClick={handleSubmit}
            isDisabled={!prompt && !imageURL}
            isLoading={isGenerating}
          >
            Generate
          </Button>
          {/* Display the generated image */}
          {generatedImageUrl && (
            <div className={styles.generatedImageContainer}>
              <h2>Generated Image</h2>
              <img
                src={generatedImageUrl}
                alt="Generated"
                className={styles.generatedImage}
              />
            </div>
          )}
        </div>
      </div>
    </LicaPage>
  );
}

export default FluxPage;
