import { ForwardedRef, forwardRef } from 'react';
import { Player, RenderPoster } from '@remotion/player';

import Template from './template';

const RemotionPlayer = forwardRef(({
  data, template, clickToPlay = true, customNumber = undefined,
}: {
  data: any;
  template?: any;
  clickToPlay?: boolean,
  customNumber?: number
}, ref: ForwardedRef<any>) => {
  let duration = 0;

  if (template) {
    duration = data.duration * data.fps;
  } else {
    data.slides?.forEach((slide: any) => {
      duration += Math.ceil(slide.duration * data.fps);
    });
  }

  const renderPoster: RenderPoster = () => (
    <>
      {customNumber === 1 ? (
        <img src="/custom-1.jpeg" />
      ) : customNumber === 2 ? (
        <img src="/custom-2.jpeg" />
      ) : customNumber === 3 ? (
        <img src="/custom-3.jpeg" />
      ) : null}
    </>
  );

  return (
    <Player
      ref={ref}
      style={{ width: '100%', borderRadius: "8px" }}
      component={template || Template}
      inputProps={{
        player: ref,
        ...data,
      }}
      durationInFrames={duration || 1}
      fps={data.fps}
      compositionWidth={data.width}
      compositionHeight={data.height}
      autoPlay={false}
      renderPoster={customNumber ? renderPoster : undefined}
      showPosterWhenUnplayed
      posterFillMode="player-size"
      clickToPlay={clickToPlay}
      controls
    />
  );
});

RemotionPlayer.displayName = 'RemotionPlayer';

export default RemotionPlayer;
