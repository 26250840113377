import styled from "styled-components";
import Text from "./text";
import { useGsapTimeline } from "../animation/gsap";
import { AnimationFactory } from "../animation/animation";
import { useRef } from "react";

const ListItem = styled.li`
    list-style: outside;
    clip-path: none !important;
`;

const useItemAnimations = (textRef: any, animation: any, index: any) => {
    useGsapTimeline(() => {
        const animationFactory = AnimationFactory.createAnimation(animation.type);
        const { gsapTimeline } = animationFactory.createTimeline(textRef.current, {
            delay: 0 + index,
            ...animation.attributes,
            duration: animation?.attributes?.duration,
        });
        return gsapTimeline;
    });
};

const AnimatedItem = ({ content, itemProps, index, animation }: any) => {
    const textRef = useRef<any>(null);
    console.log(animation);
    useItemAnimations(textRef, animation, index);

    return (
        <ListItem
            key={index}
            style={{
                marginBottom: index === itemProps.items.length - 1 ? 0 : "8px",
            }}
            ref={textRef}
        >
            <Text
                text={content}
                style={{
                    ...itemProps.itemStyle,
                }}
            />
        </ListItem>
    );
};

const GradientList = (props: { items: any[], style: any, itemStyle: any, animation: any }) => {
    return (
        <div style={{ ...props.style }}>
            <ul style={{ listStyle: "outside" }}>
                {props.items.map((content, index) => (
                    <AnimatedItem
                        content={content}
                        animation={props.animation[index]}
                        itemProps={props}
                        index={index}
                    />
                ))}
            </ul>
        </div>
    );
};

export default GradientList;
