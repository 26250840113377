import { ReactElement, useRef } from "react";
import { gsap } from "gsap";
// import { TextPlugin } from "gsap/TextPlugin";

import { useGsapTimeline } from "../animation/gsap";

// gsap.registerPlugin(TextPlugin);

interface Props {
	children: ReactElement | null,
	animation: "wipe" | "rise" | "pan" | "fade" | null,
	delay?: number
}

const AnimatedOld = ({children, animation}: Props) => {
	const container = useRef<HTMLDivElement>(null);
	let baseStyle = {};

	if (animation === "wipe") {
		baseStyle = {
			clipPath: "polygon(0 0, 0 0, 0 100%, 0 100%)",
		};
	}

	useGsapTimeline<HTMLDivElement>(() => {
		const gsapTimeline = gsap.timeline();
		if (animation === "wipe") {
			return gsapTimeline.to(container.current, {
				duration: 2,
				clipPath: "polygon(0 0, 100% 0, 100% 100%, 0 100%)",
			});
		}
		if (animation === "rise") {
			return gsapTimeline.from(container.current, {
				duration: 4,
				y: 50,
				opacity: 0,
				ease: "power3.out",
			});
		}
		if (animation === "pan") {
			return gsapTimeline.from(container.current, {
				duration: 4,
				opacity: 0,
				x: -50,
				ease: "power3.out",
			});
		}
		if (animation === "fade") {
			return gsapTimeline.from(container.current, {
				duration: 3,
				opacity: 0,
				ease: "power2.out",
			});
		}
		return gsapTimeline;
	});

	return (
		<div ref={container} style={baseStyle}>
			{children}
		</div>
	);
};

export default AnimatedOld;
