const Text = ({ textData }: {textData: any}) => {
	return (
		<div
			className="flex-auto overflow-scroll no-scrollbar"
			style={{
				maxWidth: "560px",
				color: "#909090",
			}}
			dangerouslySetInnerHTML={{__html: textData}}
		/>
	);
};

export default Text;
