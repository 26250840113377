import React, { useContext } from 'react';
import {
  Checkbox, Image, Select, SelectItem, Spacer,
} from '@nextui-org/react';
import { Icon } from '@iconify/react';

import AuthContext from '@/auth/context';

import styles from './index.module.css';

interface ContentOptions {
  presenters: any[];
  template_styles: any[];
  orientations?: string[];
  templates: any[];
  voice_options: any[];
  available_voices: any[];
  show_music_checkbox: boolean;
  show_voiceover_checkbox: boolean;
}

interface IProps {
  controls: any;
  setControls: React.Dispatch<React.SetStateAction<any>>;
  showTitle: boolean;
  contentOptions: ContentOptions;
}

function Video(
  {
    controls, setControls, showTitle, contentOptions,
  }: IProps,
) {
  const authContext = useContext(AuthContext);

  const setStylePrompt = (stylePrompt: string) => {
    setControls({
      ...controls,
      stylePrompt,
    });
  };

  const setVoiceover = (voiceover: boolean) => {
    setControls({
      ...controls,
      voiceover,
    });
  };

  const setMusic = (music: boolean) => {
    setControls({
      ...controls,
      music,
    });
  };

  const setVoiceType = (voiceType: string) => {
    setControls({
      ...controls,
      voiceType,
    });
  };

  const setOrientation = (orientation: string) => {
    setControls({
      ...controls,
      orientation,
    });
  };

  const setTemplateStyle = (templateStyle: string) => {
    setControls({
      ...controls,
      templateStyle,
    });
  };

  const setTemplateId = (templateId: string) => {
    setControls({
      ...controls,
      templateId,
    });
  };

  const setVoiceId = (voiceId: string) => {
    setControls({
      ...controls,
      voiceId,
    });
  };
  const allOrientations = ['portrait', 'landscape', 'square'];

  return (
    <div className="flex flex-col w-full mt-8">
      {showTitle ? (
        <>
          <label className={styles.label} htmlFor="video-input">For Video</label>
          <Spacer y={3} />
        </>
      ) : null}
      <label className='pl-1 xsm:text-base text-lg font-medium'>
        Prompt Style
      </label>
      <textarea
        value={controls.stylePrompt}
        className="resize-none rounded-[10px] w-full xsm:text-base text-lg outline-none minimal-scrollbar mt-2 bg-primary-light dark:!bg-[#1C1C1C] border dark:border-borderColor-dark border-borderColor-light px-4 py-2 dark:text-secondary-dark text-secondary-light placeholder:text-[#475467] dark:placeholder:text-[#737373]"
        placeholder="Enter your prompt style, tone to describe the video output"
        onChange={(e) => setStylePrompt(e.target.value)}
      />
      <div className="flex gap-4 mt-3 text-sm h-20  font-medium dark:text-secondary-dark text-[#756A6A]">
        <div
          className="max-md:w-full w-1/2 min-lg:w-1/3 xl:!w-1/4 h-full text-center pb-1 flex items-center justify-center pl-1 pr-2 rounded-xl dark:bg-[#181818] bg-white border border-[#d3d3d3] dark:border-[#404040] cursor-pointer"
          onClick={() => setStylePrompt('Summary reel for instagram in a soft american female voice in pastel gradient color scheme')}
        >
          Summary reel for instagram in a soft american female voice in pastel gradient color scheme
        </div>
        <div
          className="max-md:hidden w-1/2 min-lg:w-1/3 xl:!w-1/4 h-full text-center pb-1 flex items-center justify-center pl-1 pr-2 rounded-xl dark:bg-[#181818] bg-white border border-[#d3d3d3] dark:border-[#404040] cursor-pointer"
          onClick={() => setStylePrompt('Product video for tiktok in a simple minimal template with electronic beats background music')}
        >

          Product video for tiktok in a simple minimal template with electronic beats background music
        </div>
        <div
          className="hidden w-1/3 xl:w-1/4 h-full text-center pb-1 min-lg:flex items-center justify-center pl-1 pr-2 rounded-xl dark:bg-[#181818] bg-white border border-[#d3d3d3] dark:border-[#404040] cursor-pointer"
          onClick={() => setStylePrompt('Visually stunning template, young male teenager voice, brief overview of the content, classical jazz music')}
        >

          Visually stunning template, young male teenager voice, brief overview of the content, classical jazz music
        </div>
        <div
          className="w-1/4 hidden h-full text-center pb-1 xl:!flex items-center justify-center pl-1 pr-2 rounded-xl dark:bg-[#181818] bg-white border border-[#d3d3d3] dark:border-[#404040] cursor-pointer"
          onClick={() => setStylePrompt('Explainer video for youtube shorts with bright yellow template and clean font, highlight key observations and takeaways')}
        >

          Explainer video for youtube shorts with bright yellow template and clean font, highlight key observations and takeaways
        </div>
      </div>
      {contentOptions.show_voiceover_checkbox ? (
        <>
          <Spacer y={4} />
          <Checkbox
            defaultSelected
            radius="sm"
            color="primary"
            classNames={{
              icon: ['dark:text-secondary-dark text-secondary-light'],
              label: ['text-small', 'dark:text-secondary-dark text-secondary-light text-opacity-80'],
            }}
            isSelected={controls.voiceover}
            onValueChange={setVoiceover}
          >
            Voiceover
          </Checkbox>
          <Spacer y={4} />
          <div className="w-full p-4 border border-secondary border-opacity-20 rounded-2xl">
            <div className="flex gap-2.5 overflow-auto">
              {contentOptions.voice_options.map((voice: any) => (
                <div
                  key={voice.id}
                  className={`${styles.genderButton} ${controls.voiceType === voice.id ? 'bg-tertiary dark:text-primary-dark text-primary-light' : ''}`}
                  onClick={() => {
                    setVoiceType(voice.id);
                  }}
                >
                  {voice.label}
                </div>
              ))}
            </div>
          </div>
        </>
      ) : null}
      {contentOptions.show_music_checkbox ? (
        <>
          <div className="flex items-center mt-8 h-10">
            <input
              type="checkbox"
              id="music-checkbox"
              className={`w-5 h-5 cursor-pointer appearance-none rounded-[4px] focus:ring-secondary ${controls.music
                ? "border-gray-300 dark:checked:bg-[#93C5FD] checked:bg-[#6E400A] invert-[1] dark:invert-0 checked:bg-center checked:bg-no-repeat checked:bg-[length:60%_50%]"
                : "border dark:border-[#575757] border-[#d3d3d3] bg-primary-light dark:!bg-[#1C1C1C]"
                }`}
              style={{
                backgroundImage: controls.music
                  ? `url(/icons/tick.svg)`
                  : "none",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "60% 50%",
              }}
              checked={controls.music}
              onChange={(e) => setMusic(e.target.checked)}
            />
            <label
              htmlFor="music-checkbox"
              className="ml-2 dark:text-secondary-dark text-secondary-light cursor-pointer"
            >
              Add music
            </label>
          </div>
        </>
      ) : null}
      {(contentOptions.orientations && contentOptions.orientations.length > 0) ? (
        <>
          <Spacer y={9} />
          <Select
            classNames={{
              label: ['!text-secondary !pl-1 !text-base'],
              selectorIcon: ['!text-secondary'],
              value: ['!text-secondary !text-sm'],
            }}
            labelPlacement="outside"
            label="Orientation"
            placeholder="Select"
            className="sm:w-full w-[230px] !p-0 rounded-xl border !bg-primary-light dark:border-[#404040] dark:!bg-[#363636]"
            selectedKeys={new Set(controls.orientation ? [controls.orientation] : [])}
            onChange={(e) => setOrientation(e.target.value)}
            startContent={controls.orientation ? (
              <Icon
                icon={
                  controls.orientation === 'portrait' ? (
                    'tabler:rectangle-vertical'
                  ) : controls.orientation === 'landscape' ? (
                    'fluent:rectangle-landscape-12-regular'
                  ) : 'fluent:square-12-regular'
                }
                fontSize="1.5rem"
                color="secondary"
              />
            ) : null}
            style={{
              height: '44px',
            }}
          >
            {allOrientations.map((orientation) => {
              const isAvailable = contentOptions.orientations && contentOptions.orientations.includes(orientation);

              const isComingSoon = !isAvailable;
              const label = orientation === 'portrait'
                ? 'Portrait (9:16)'
                : orientation === 'landscape'
                  ? 'Landscape (16:9)'
                  : 'Square (1:1)';
              const comingSoonLabel = isComingSoon ? ' - Coming Soon' : '';
              const fullLabel = label + comingSoonLabel;

              const icon = orientation === 'portrait'
                ? 'tabler:rectangle-vertical'
                : orientation === 'landscape'
                  ? 'fluent:rectangle-landscape-12-regular'
                  : 'fluent:square-12-regular';

              const textColor = isComingSoon ? 'text-gray-400 dark:text-gray-400' : 'text-primary dark:text-secondary-dark';
              const cursorStyle = isComingSoon ? 'cursor-not-allowed' : 'cursor-pointer';

              return (
                <SelectItem
                  classNames={{
                    title: [textColor],
                  }}
                  key={orientation}
                  value={isComingSoon ? '' : orientation}
                  startContent={<Icon icon={icon} className='text-secondary-light dark:text-secondary-dark' fontSize={16} />}
                  style={{ pointerEvents: isComingSoon ? 'none' : 'auto', opacity: isComingSoon ? 0.75 : 1 }}
                  className={`${cursorStyle}`}
                >
                  {fullLabel}
                </SelectItem>
              );
            })}
          </Select>

        </>
      ) : null}
      {contentOptions.template_styles.length > 0 ? (
        <>
          <div className="dark:text-secondary-dark text-secondary-light mt-8 pl-4 font-medium">Choose Style</div>
          <div
            className="flex gap-3 w-full max-w-[70rem] minimal-scrollbar-horizontal mt-2 rounded-xl p-2 bg-primary-light dark:!bg-[#1C1C1C] border dark:border-borderColor-dark"
            style={{
              overflowX: 'scroll', WebkitOverflowScrolling: 'touch', scrollBehavior: 'smooth'
            }}
          >
            {contentOptions.template_styles.map((tStyle: any) => (
              <div key={tStyle.id} className="flex-shrink-0 w-[100px]">
                <button
                  onClick={() => setTemplateStyle(tStyle.id)}
                  className={`${controls.templateStyle === tStyle.id ? 'border-4 border-tertiary' : ''} rounded-xl relative`}
                >
                  <Image
                    width={120}
                    alt={tStyle.label}
                    src={tStyle.image}
                  />
                  <div
                    className="text-white text-small absolute flex items-center justify-center w-full h-full top-0 z-10"
                    style={{ overflowX: 'hidden', overflowY: 'hidden' }}
                  >
                    {tStyle.label}
                  </div>
                </button>
              </div>
            ))}
          </div>
        </>
      ) : null}
      {contentOptions.available_voices?.length > 0 ? (
        <>
          <Spacer y={9} />
          <Select
            classNames={{
              label: ['dark:!text-secondary-dark text-secondary-dark !text-opacity-80'],
              selectorIcon: ['dark:!text-secondary-dark text-primary-dark'],
              value: ['dark:!text-secondary-dark text-primary-dark'],
            }}
            labelPlacement="outside"
            label="Choose Voice"
            placeholder="Select"
            className="max-w-xs !text-secondary-dark"
            selectedKeys={new Set(controls.voiceId ? [controls.voiceId] : [])}
            onChange={(e) => setVoiceId(e.target.value)}
          >
            {contentOptions.available_voices?.map((voice) => (
              <SelectItem
                classNames={{
                  title: ['dark:!text-secondary-dark text-primary-dark'],
                }}
                key={voice.id}
                value={voice.id}
              >
                {voice.label}
              </SelectItem>
            ))}
          </Select>
        </>
      ) : null}
      {authContext.currentUser?.email && (authContext.currentUser.email.includes('@lica.world') || authContext.currentUser.email.includes('queencytabligan15@gmail.com') || authContext.currentUser.email.includes('anugyascontact@gmail.com') || (authContext.currentUser.email.includes('agnieszka.m.szefer@gmail.com'))) && (
        <>
          <Spacer y={9} />
          <Select
            classNames={{
              label: ['dark:!text-secondary-dark text-secondary-dark !text-opacity-80'],
              selectorIcon: ['dark:!text-secondary-dark text-primary-dark'],
              value: ['dark:!text-secondary-dark text-primary-dark'],
            }}
            labelPlacement="outside"
            label="Choose Template ID"
            placeholder="Select"
            className="max-w-xs"
            selectedKeys={new Set(controls.templateId ? [controls.templateId] : [])}
            onChange={(e) => setTemplateId(e.target.value)}
          >
            {contentOptions.templates.map((template) => (
              <SelectItem
                classNames={{
                  title: ['dark:text-secondary-dark text-primary-dark'],
                }}
                key={template}
                value={template}
              >
                {template}
              </SelectItem>
            ))}
          </Select>
        </>
      )}
    </div>
  );
}

export default Video;
