import React, { useEffect, useState } from 'react';

import { prefetch } from 'remotion';
import Button from '../components/Button';
import RemotionPlayer from '../remotion/RemotionPlayer';

export default function () {
  const [text, setText] = useState('');
  const [config, setConfig] = useState<any>({});
  const [isMediaCached, setIsMediaCached] = useState(false);

  function getMediaLinks(data: any) {
    let links: string[] = [];
    for (const key in data) {
      const val = data[key];

      if (val === null) continue;

      if (typeof val === 'object') {
        links = links.concat(getMediaLinks(val));
      } else if (Array.isArray(val)) {
        val.forEach((obj: any) => {
          links = links.concat(getMediaLinks(obj));
        });
      } else if (typeof val === 'string') {
        try {
          const url = new URL(val);
          const isMedia = /\.(mp4|webm|jpg|jpeg|svg|png|webp|avif|gif|mp3)$/.test(url.pathname);
          if (isMedia) {
            links.push(val);
          }
        } catch (e) { /* empty */
        }
      }
    }
    return links;
  }

  useEffect(() => {
    if (config) {
      const promises: any[] = [];
      const mediaLinks = getMediaLinks(config);
      mediaLinks.forEach((link) => {
        promises.push(prefetch(link).waitUntilDone());
      });
      Promise.allSettled(promises).then(() => {
        setIsMediaCached(true);
      });
    }
  }, [config]);

  const onClickCreate = async () => {
    if (!text) return;

    try {
      setConfig(JSON.parse(text));
      setIsMediaCached(false);
    } catch (e) {
      alert('Invalid input');
    }
  };

  return (
    <div className="h-screen flex p-6 gap-8">
      <div className="basis-1/3">
        <textarea
          placeholder="Enter configuration"
          className="w-full h-[500px] border rounded-lg p-4 mb-5 resize-none"
          value={text}
          onChange={(e) => setText(e.target.value)}
        />
        <Button onClick={onClickCreate}>Create</Button>
      </div>
      <div className={`basis-2/3 mx-auto ${config.width / config.height > 1 ? 'max-w-[600px]' : 'max-w-[400px]'}`}>
        {Object.keys(config).length > 0 && isMediaCached ? (
          <RemotionPlayer
            data={config}
            clickToPlay={false}
          />
        ) : null}
      </div>
    </div>
  );
}
