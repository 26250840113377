import { useNavigate } from 'react-router-dom';

export default function Content({ item }: any) {
    const navigate = useNavigate();

    const onClick = () => {
        navigate(`/pages/${item.page_id}`);
    };

    const formattedDate = new Date(item.created_at).toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
    }) + ', ' + new Date(item.created_at).toLocaleDateString('en-GB', {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
    });
    return (
        <div onClick={onClick} className='flex flex-col xsm:w-72 sm:w-80 w-96 bg-sectionBackground-light dark:bg-sectionBackground-dark rounded-2xl p-4 cursor-pointer'>
            <img className='h-52 self-center text-lg rounded-xl mb-6' src={item.thumbnail_url || "https://images.unsplash.com/photo-1726402919430-a4521f2b1fa6?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"} />
            <h3 className="font-medium truncate mb-2">{item.data.title}</h3>
            <p className="truncate mb-2"><strong className='!font-medium text-[#787878] mr-1'>Prompt :</strong>{item.prompt}</p>
            <p className="text-sm truncate text-[#979A9D]">{formattedDate}</p>
        </div>
    );
}
