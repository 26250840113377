import React from 'react';
import { Audio } from 'remotion';
import { useAudioData } from '@remotion/media-utils';

import { AudioViz as AudioComp, Image, PaginatedSubtitles } from './comps';

interface InputProps {
  src?: string;
  srt_file?: string;
  authorName?: string;
  image?: string;
  music?: any;
  styles?: any;
}

function AudioViz({
  src, srt_file, image, authorName, music, styles,
}: InputProps) {
  const audioData = useAudioData(src || '');

  const currentStyles = {
    subtitle_color: styles ? styles.subtitle_color : '#FFFFFF',
    subtitle_background_color: styles ? styles.subtitle_background_color : '#000000',
    active_subtitle_color: styles ? styles.active_subtitle_color : '#C4C0F7',
    wave_color: styles ? styles.wave_color : '#BFA8F280',
    background_color: styles ? styles.background_color : 'linear-gradient(154deg, #6D41FF -12.95%, #A679FF 63.49%)',
    author_color: styles ? styles.author_color : '#FFFFFF',
  };

  if (!audioData) {
    return null;
  }

  return (
    <div
      className="w-full h-full flex flex-col justify-between py-6 px-4"
      style={{
        background: currentStyles.background_color,
      }}
    >
      <div className="flex gap-4">
        {music ? <Audio loop src={music.src} volume={music.volume} /> : null}
        {image && (
          <div style={{
            minWidth: '100px', minHeight: '100px', maxWidth: '100px', maxHeight: '100px',
          }}
          >
            <Image src={image} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
          </div>
        )}
        <div className="flex-auto">
          <PaginatedSubtitles
            subtitlesFileName={srt_file || ''}
            linesPerPage={10}
            subtitlesBackgroundColor={currentStyles.subtitle_background_color}
            subtitlesTextColor={currentStyles.subtitle_color}
            subtitlesActiveTextColor={currentStyles.active_subtitle_color}
            subtitlesZoomMeasurerSize={1}
            subtitlesLineHeight={25}
            audioOffsetInSeconds={0}
            style={{
              fontWeight: 600,
              fontSize: '20px',
            }}
          />
        </div>
      </div>
      <AudioComp
        audioSrc={src || ''}
        waveColor={currentStyles.wave_color}
        freqRangeStartIndex={4}
        waveLinesToDisplay={29}
        numberOfSamples={256}
        mirrorWave
      />
      <h1 style={{ color: currentStyles.author_color }} className="text-base font-medium">{authorName}</h1>
    </div>
  );
}

export default AudioViz;
