import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { Button, Spacer } from '@nextui-org/react';
import { Icon } from '@iconify/react';

import AuthContext from '@/auth/context';

function Blog({
  data, contentId, responseData, publicEdit = false,
}: { data: any, responseData: any, contentId: string, publicEdit?: boolean }) {
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();

  const handleEditClick = async () => {
    navigate(`/content/${contentId}/edit`);
  };

  function TitleCard() {
    return (
      <div className="flex items-center justify-start gap-5 p-0 w-full">
        <div className="flex-col">
          <div className="flex gap-5 items-center justify-start">
            <div className="flex items-center justify-between">
              <h4 className="text-small font-semibold leading-none text-default-600">
                {responseData.user_info?.name}
              </h4>
              <div className="mx-3 w-2 h-2 rounded-full bg-[#c0c0c0]" />
              <h4 className="text-small font-semibold leading-none text-default-600">
                {dayjs(data.created_at).format('DD MMM YYYY')}
              </h4>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="h-fit w-full flex flex-col justify-center items-center">
      <div className="max-w-[35rem] flex flex-col">
        <TitleCard />
        <Spacer y={4} />
        <div
          className="flex-auto !text-black my-5 h-full w-full [&_div]:!p-0 [&_section]:!p-0 [&_h2]:!mb-5 overflow-scroll no-scrollbar"
          style={{
            maxWidth: '560px',
            color: 'red',
          }}
          dangerouslySetInnerHTML={{ __html: data.text_data.text }}
        />
        {publicEdit || responseData.user_info.email === authContext.currentUser?.email ? (
          <Button
            radius="full"
            size="md"
            style={{
              background: 'var(--BG-6, #E0E7FF)',
              color: 'var(--Primary-Color, #5046E5)',
            }}
            onClick={handleEditClick}
            startContent={<Icon icon="iconamoon:edit" />}
          >
            Edit
          </Button>
        ) : null}
      </div>
    </div>
  );
}

export default Blog;
