import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@nextui-org/react';
import { Icon } from '@iconify/react';

import AuthContext from '@/auth/context';
import { downloadContent } from '@/services/content';
import RemotionPlayer from '@/remotion/RemotionPlayer';
import { getCurrentDeviceType } from '@/utils/common';
import ShareButton from '@/pages/v3/components/ShareButton';


function Video({ contentId, videoData, responseData }: {
  contentId: string;
  videoData: any;
  responseData: any;
}) {
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();

  const [isDownloading, setIsDownloading] = useState(false);

  const handleDownloadClick = async () => {
    if (isDownloading) return;

    setIsDownloading(true);
    await downloadContent(videoData);
    setIsDownloading(false);
  };

  const handleEditClick = async () => {
    navigate(`/content/${contentId}/edit`);
  };

  return (
    <div className="h-full flex flex-col">
      <div className="h-full flex-col-reverse min-lg:flex-row flex items-center justify-between gap-9">
        <div className="h-full min-w-[20rem] w-full min-lg:w-3/12 flex bg-sectionBackground-light dark:bg-sectionBackground-dark rounded-3xl p-6 py-10 flex-col gap-8">
          <div className="text-2xl font-medium sm:text-medium">
            {responseData.output.title}
          </div>
          <div className='text-lg sm:text-base'>
            <h4 className="font-medium">Story</h4>
            <p className="w-full minimal-scrollbar minimal-scrollbar-horizontal text-wrap max-h-80 overflow-y-auto mt-1 rounded-xl">
              {responseData.input_params.prompt || ""}
            </p>
          </div>
          <div className='text-lg sm:text-base'>
            <label className='font-medium'>
              Style Instruction
            </label>
            <p
              className="rounded-[10px] minimal-scrollbar-horizontal text-wrap max-h-40 w-full minimal-scrollbar overflow-y-auto mt-1 dark:text-secondary-dark text-secondary-light placeholder:text-[#737373]"
            >
              {responseData.input_params.style_prompt || ""}
            </p>
          </div>
        </div>
        <div className="bg-sectionBackground-light grow dark:bg-sectionBackground-dark w-full h-[80vh] min-lg:w-8/12 rounded-xl sm:h-[60vh] min-lg:h-full p-6 flex flex-col items-center">
          <div className="flex items-center justify-center gap-4 mb-6">
            <ShareButton url={`${window.origin}/pages/${responseData?.page_id}`} key={1} />
            <Button
              className="dark:text-primary-dark text-primary-light rounded-xl dark:bg-secondary-dark bg-tertiary-light text-sm font-medium z-10 flex items-center justify-center h-10"
              onClick={handleDownloadClick}
              isLoading={isDownloading}
            >
              {!isDownloading && <img className='invert dark:invert-0' src="/icons/download-icon.svg" alt="Download" />}
              Download
            </Button>
            {responseData.user_info.email === authContext.currentUser?.email ? (
              <Button
                className="dark:text-primary-dark text-primary-light rounded-xl dark:bg-secondary-dark bg-tertiary-light text-sm font-medium z-10 flex items-center justify-center w-20 h-10"
                onClick={handleEditClick}
                startContent={<Icon icon="iconamoon:edit" />}
              >
                <img className='invert dark:invert-0' src="/icons/edit-icon.svg" alt="Edit" />
                Edit
              </Button>
            ) : null}
          </div>
          <div className="flex flex-col flex-auto items-center w-full">
            <div
              style={{
                aspectRatio: videoData.width / videoData.height,
              }}
              className="flex-auto max-w-full max-h-full"
            >
              {getCurrentDeviceType() === 'phone' && videoData.download_links?.media ? (
                <div className="flex items-center justify-center">
                  <video src={videoData.download_links.media} controls />
                </div>
              ) : (
                <RemotionPlayer data={videoData} clickToPlay={false} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Video;
